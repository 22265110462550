import React, { useEffect } from 'react';
import styles from './Nosotros.module.scss';
import Mission from '../../Components/AboutUs/mission/Mission';
import Founders from '../../Components/AboutUs/founders/Founders';
import ContactSection from '../../Components/ContactSection/ContactSection';
import PrincipalSection from '../../Components/AboutUs/Principal/PrincipalSection';
import ConfianEnNosotros from '../../Components/ConfianEnNosotros/ConfianEnNosotros';

const Nosotros = () => {
  useEffect(() => {
    document.title = "Nosotros - simplepass";
  }, []);

  return (
    <section className={styles.nosotrosBody}>
      <div className={styles.nosotrosCuerpo + " " + styles.columnCenter}>

      </div>
      <PrincipalSection />
      <Founders />
      <Mission />
      <ContactSection />
      <ConfianEnNosotros />
    </section>
  );
};

export default Nosotros;
