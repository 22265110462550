import React, { useState, useEffect } from 'react';
import styles from './PanelAdmin.module.scss';
import { useAuth } from '../../Context/AuthProvider';
import axios from 'axios';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import Totales6MesesGrafico from '../../Components/Graficos/Totales6Meses/Totales6MesesGrafico';
import UltimosProductores from '../../Components/Graficos/UltimosProductores/UltimosProductores';
import variables from '../../Context/Variables';

const PanelAdmin = () => {
    const { estaAutenticado, jwt } = useAuth();
    const [estadisticas, setEstadisticas] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEstadisticas = async () => {
            try {
                const response = await axios.get(
                    `${variables.API_BASE_URL}/api/ventas/estadisticas`,
                    {
                        headers: { Authorization: `Bearer ${jwt}` }
                    }
                );
                setEstadisticas(response.data);
            } catch (error) {
                console.error('Error al cargar estadísticas:', error);
            } finally {
                setLoading(false);
            }
        };

        if (estaAutenticado && jwt) {
            fetchEstadisticas();
        }
    }, [jwt, estaAutenticado]);

    if (!estaAutenticado) {
        return <NoPermissionPage />;
    }

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS'
        }).format(amount);
    };

    return (
        <div className={styles.dashboard}>
            <div className={styles.header}>
                <h1 className={styles.titulo}>Panel de Administración</h1>
                <div className={styles.headerStats}>
                    <div className={`${styles.statCard} ${loading ? styles.loading : ''}`}>
                        {loading ? (
                            <div className={styles.skeletonContent}>
                                <div className={styles.skeletonLabel}></div>
                                <div className={styles.skeletonValue}></div>
                            </div>
                        ) : (
                            <>
                                <span className={styles.statLabel}>Ventas último mes</span>
                                <span className={styles.statValue}>
                                    {formatCurrency(estadisticas?.montoTotalUltimoMes || 0)}
                                </span>
                            </>
                        )}
                    </div>
                    <div className={`${styles.statCard} ${loading ? styles.loading : ''}`}>
                        {loading ? (
                            <div className={styles.skeletonContent}>
                                <div className={styles.skeletonLabel}></div>
                                <div className={styles.skeletonValue}></div>
                            </div>
                        ) : (
                            <>
                                <span className={styles.statLabel}>Pagos último mes</span>
                                <span className={styles.statValue}>
                                    {estadisticas?.cantidadPagosUltimoMes || 0}
                                </span>
                            </>
                        )}
                    </div>
                    <div className={`${styles.statCard} ${loading ? styles.loading : ''}`}>
                        {loading ? (
                            <div className={styles.skeletonContent}>
                                <div className={styles.skeletonLabel}></div>
                                <div className={styles.skeletonValue}></div>
                            </div>
                        ) : (
                            <>
                                <span className={styles.statLabel}>Venta promedio</span>
                                <span className={styles.statValue}>
                                    {formatCurrency(estadisticas?.promedioHistorico || 0)}
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.contButtons}>
                <div className={styles.actionCards}>
                    <button
                        className={`${styles.actionCard} ${styles.pagosCard}`}
                        onClick={() => window.location.href = '/panel-admin/pagos'}
                    >
                        <i className="fas fa-credit-card"></i>
                        <span>Ver Pagos</span>
                    </button>
                    <button
                        className={`${styles.actionCard} ${styles.facturasCard}`}
                        onClick={() => window.location.href = '/panel-facturas'}
                    >
                        <i className="fas fa-file-invoice"></i>
                        <span>Ver Facturas</span>
                    </button>
                </div>
            </div>

            <div className={styles.mainContent}>
                <div className={styles.graphGrid}>
                    <Totales6MesesGrafico jwt={jwt} />
                    <UltimosProductores jwt={jwt} />
                </div>
            </div>
        </div>
    );
};

export default PanelAdmin;
