import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { isValidPhoneNumber } from "react-phone-number-input";
import styles from "./VerDetalles.module.scss";
import variables from "../../Context/Variables";
import { useAuth } from "../../Context/AuthProvider";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import PhoneInputComponent from "../../Components/PhoneInputComponent/PhoneInputComponent";
import { Icon } from "@iconify/react";
import Alert from '../../Components/Alert/Alert';

const VerDetalles = () => {
  const { idRegistro, eventoId } = useParams();
  const { jwt } = useAuth();
  const navigate = useNavigate();
  const [mostrarExplicacion, setMostrarExplicacion] = useState(false);

  const [data, setData] = useState({
    idEnvio: "",
    tipoEntrada: "",
    cantidad: "",
    numeroTelefono: "",
    email: "",
  });
  const [originalValues, setOriginalValues] = useState({ numeroTelefono: "", email: "" });
  const [isEditing, setIsEditing] = useState({ email: false, telefono: false });
  const [wasModified, setWasModified] = useState(false);
  const [loading, setLoading] = useState({
    whatsapp: false,
    email: false,
    fetchingData: false
  });
  const [error, setError] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const inputRef = useRef(null);
  const [nombreCliente, setNombreCliente] = useState("");

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchDetalles = async () => {
    setLoading(prev => ({ ...prev, fetchingData: true }));
    try {
      const response = await axios.get(
        `${variables.API_BASE_URL}/api/registro/${idRegistro}/${eventoId}`,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      const fetchedData = {
        idEnvio: response.data.id || "",
        tipoEntrada: response.data.nombreTipoDeTicket || "",
        cantidad: response.data.cantidad || "",
        numeroTelefono: response.data.celular?.trim() || "",
        email: response.data.email?.trim() || "",
      };
      setNombreCliente(response.data.nombreCompleto);
      setData(fetchedData);
      setOriginalValues({ numeroTelefono: fetchedData.numeroTelefono, email: fetchedData.email });
      setError(null);
    } catch {
      setError("Error al cargar los detalles del registro. Intenta nuevamente más tarde.");
    } finally {
      setLoading(prev => ({ ...prev, fetchingData: false }));
    }
  };

  useEffect(() => {
    fetchDetalles();
  }, [idRegistro, eventoId, jwt]);

  useEffect(() => {
    setWasModified(
      data.numeroTelefono !== originalValues.numeroTelefono ||
      data.email !== originalValues.email
    );
  }, [data, originalValues]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        (isEditing.email || isEditing.telefono)
      ) {
        setIsEditing({ email: false, telefono: false });
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape" && (isEditing.email || isEditing.telefono)) {
        setIsEditing({ email: false, telefono: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isEditing]);

  const handlePhoneChange = (phone) => {
    setData((prev) => ({ ...prev, numeroTelefono: phone.trim() }));
    setIsPhoneValid(isValidPhoneNumber(phone));
  };

  const sendWhatsAppMessage = async () => {
    setLoading(prev => ({ ...prev, whatsapp: true }));
    const requestBody = {
      registroId: idRegistro,
      eventoId: eventoId,
      celular: data.numeroTelefono.trim(),
    };
    try {
      await axios.post(
        `${variables.API_BASE_URL}/api/registro/whatsapp/resend-tickets`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );

      Alert({
        tipo: 'success',
        titulo: 'Mensaje enviado',
        descripcion: 'El mensaje fue enviado exitosamente.',
        duracion: 3000,
      });
      return true;
    } catch (error) {
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Error al enviar el mensaje. Intenta nuevamente.',
        duracion: 3000,
      }
      );
      return false;
    } finally {
      setLoading(prev => ({ ...prev, whatsapp: false }));
    }
  };

  const sendEmail = async () => {
    setLoading(prev => ({ ...prev, email: true }));
    try {
      const requestBody = {
        idRegistro: idRegistro,
        eventoId: eventoId,
        emailNuevo: data.email.trim(),
      };

      const response = await axios.post(
        `${variables.API_BASE_URL}/api/registro/email/resend-tickets`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (response.status === 200) {
        Alert({
          tipo: "success",
          titulo: "Email reenviado",
          descripcion: "El email fue reenviado exitosamente.",
          duracion: 3000,
        });
        return true;
      } else {
        throw new Error("Error desconocido al reenviar el email");
      }
    } catch (error) {
      Alert({
        tipo: "error",
        titulo: "Error",
        descripcion: `Error al reenviar el email: ${error.response?.data || error.message}`,
        duracion: 13000,
      });
      return false;
    } finally {
      setLoading(prev => ({ ...prev, email: false }));
    }
  };

  return (
    <div className={styles.detallesEnvio}>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.card} ref={inputRef}>
        <h1>Reenviar tickets <Icon icon="solar:ticket-bold-duotone" /></h1>
        <div className={styles.fondo}>
          <h3 onClick={() => setMostrarExplicacion(!mostrarExplicacion)} className={styles.comoFunciona}>¿Cómo funciona? {mostrarExplicacion ? <Icon icon="solar:alt-arrow-up-outline" /> : <Icon icon="solar:alt-arrow-down-outline" />}</h3>
          {mostrarExplicacion && (
            <>
              <p>
                Desde aca podés reenviar los tickets editando el numero de teléfono o el email y luego apretando el boton reenviar.
              </p>
            </>
          )}
        </div>
        <div className={styles.field}>
          <span className={styles.label}>ID del envío:</span>
          <span className={styles.value}>{data.idEnvio}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>Nombre del cliente:</span>
          <span className={styles.value}>{nombreCliente}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>Tipo de entrada:</span>
          <span className={styles.value}>{data.tipoEntrada}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>Cantidad:</span>
          <span className={styles.value}>{data.cantidad}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>Número de teléfono:</span>
          <div className={styles.valueWithIcons}>
            {isEditing.telefono ? (
              <PhoneInputComponent
                value={data.numeroTelefono}
                onChange={handlePhoneChange}
              />
            ) : (
              <span className={styles.value}>
                {data.numeroTelefono || "Sin registro"}
              </span>
            )}
            {isEditing.telefono &&
              data.numeroTelefono !== originalValues.numeroTelefono &&
              isPhoneValid && (
                <SubmitButton
                  isDisabled={!isPhoneValid || loading.whatsapp}
                  isLoading={loading.whatsapp}
                  onClick={() => sendWhatsAppMessage()}
                  className={styles.submitButton}
                >
                  <Icon icon="ic:round-send" style={{ marginRight: "2px" }} />
                  Reenviar
                </SubmitButton>
              )}
            {!isEditing.telefono && (
              <Icon
                icon="material-symbols:edit-outline-sharp"
                className={styles.editIcon}
                onClick={() =>
                  setIsEditing((prev) => ({ ...prev, telefono: true }))
                }
              />
            )}
          </div>
          {!isPhoneValid && (
            <div className={styles.error}>El número de teléfono no es válido.</div>
          )}
        </div>

        <div className={styles.field}>
          <span className={styles.label}>Email:</span>
          <div className={styles.valueWithIcons}>
            {isEditing.email ? (
              <input
                type="email"
                value={data.email}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, email: e.target.value.trim() }))
                }
                className={styles.input}
              />
            ) : (
              <span className={styles.value}>
                {data.email || "Sin registro"}
              </span>
            )}
            {isEditing.email &&
              data.email !== originalValues.email &&
              isValidEmail(data.email) && (
                <SubmitButton
                  isDisabled={loading.email}
                  isLoading={loading.email}
                  onClick={sendEmail}
                  className={styles.submitButton}
                >
                  <Icon icon="ic:round-send" style={{ marginRight: "2px" }} />
                  Reenviar
                </SubmitButton>
              )}
            {!isEditing.email && (
              <Icon
                icon="material-symbols:edit-outline-sharp"
                className={styles.editIcon}
                onClick={() =>
                  setIsEditing((prev) => ({ ...prev, email: true }))
                }
              />
            )}
          </div>
          {!isValidEmail(data.email) && isEditing.email && (
            <div className={styles.error}>El email no es válido.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerDetalles;
