import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import { useAuth } from '../../Context/AuthProvider';
import styles from './ProductosCaja.module.scss';
import variables from '../../Context/Variables';
import SkeletonProductoCaja from './SkeletonProductoCaja/SkeletonProductoCaja';
import Alert from '../../Components/Alert/Alert';

const defaultImageUrl = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Menus/food.webp";

const ProductosCaja = () => {
    const { eventoId, cajaId } = useParams();
    const { jwt } = useAuth();
    const [productos, setProductos] = useState([]);
    const [newProductos, setNewProductos] = useState([]);
    const [modifiedProductos, setModifiedProductos] = useState([]);
    const [deletedProductos, setDeletedProductos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [puedeSubirCambios, setPuedeSubirCambios] = useState(false);

    // Carga inicial de productos
    useEffect(() => {
        fetchProductos();
    }, [eventoId, cajaId, jwt]);

    // Monitorea cambios en productos
    useEffect(() => {
        verificarCambios();
    }, [newProductos, modifiedProductos, deletedProductos]);

    const fetchProductos = async () => {
        try {
            const response = await axios.get(
                `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/productos`,
                { headers: { Authorization: `Bearer ${jwt}` }}
            );
            setProductos(response.data.productos || []);
        } catch (error) {
            mostrarAlerta('error', 'Error', 'No se pudo cargar los productos de la caja.');
        } finally {
            setLoading(false);
        }
    };

    const verificarCambios = () => {
        const allNewComplete = newProductos.length > 0 && 
            newProductos.every(p => p.titulo && p.precio && p.imagen);
        const anyModified = modifiedProductos.length > 0;
        const anyDeleted = deletedProductos.length > 0;

        setPuedeSubirCambios(allNewComplete || anyModified || anyDeleted);
    };

    const actualizarProducto = (producto, isNew) => {
        if (isNew) {
            actualizarListaProductos(newProductos, setNewProductos, producto);
        } else if (!producto.tieneVentas) {
            actualizarListaProductos(modifiedProductos, setModifiedProductos, producto);
        }
    };

    const actualizarListaProductos = (lista, setLista, producto) => {
        const idx = lista.findIndex(p => p.id === producto.id);
        if (idx === -1) {
            setLista([...lista, producto]);
        } else {
            const updatedLista = [...lista];
            updatedLista[idx] = producto;
            setLista(updatedLista);
        }
    };

    const handleInputChange = (index, value, field) => {
        const updatedProductos = [...productos];
        const producto = { ...updatedProductos[index], [field]: value };
        updatedProductos[index] = producto;
        
        actualizarProducto(producto, typeof producto.id === 'string');
        setProductos(updatedProductos);
    };

    const handleImageChange = (index, file) => {
        if (!validarImagen(file)) {
            mostrarAlerta('error', 'Error', 'Solo se permiten imágenes en formato webp, jpg o png.');
            return;
        }

        const updatedProductos = [...productos];
        const producto = { 
            ...updatedProductos[index], 
            imagen: file, 
            imgURL: URL.createObjectURL(file) 
        };
        updatedProductos[index] = producto;

        actualizarProducto(producto, typeof producto.id === 'string');
        setProductos(updatedProductos);
    };

    const validarImagen = (file) => {
        return file && ["image/webp", "image/jpeg", "image/png"].includes(file.type);
    };

    const handleAddNewProducto = () => {
        const nuevoProducto = {
            id: `new-${Date.now()}`,
            titulo: "",
            precio: "",
            imgURL: defaultImageUrl,
            imagen: null,
        };
        setProductos([...productos, nuevoProducto]);
        setNewProductos([...newProductos, nuevoProducto]);
    };

    const handleDeleteProducto = (index) => {
        const producto = productos[index];
        
        if (producto.tieneVentas) {
            mostrarAlerta('error', 'Error', 'No se puede eliminar un producto que tiene ventas asociadas.');
            return;
        }

        if (typeof producto.id === 'string') {
            setNewProductos(newProductos.filter(p => p.id !== producto.id));
        } else {
            setDeletedProductos([...deletedProductos, producto.id]);
            setModifiedProductos(modifiedProductos.filter(p => p.id !== producto.id));
        }

        setProductos(productos.filter((_, idx) => idx !== index));
    };

    const handleSubmit = async () => {
        if (!puedeSubirCambios) {
            mostrarAlerta('error', 'Error', 'Por favor, realice algún cambio antes de enviar.');
            return;
        }

        setIsLoadingSubmit(true);

        try {
            const formData = prepararFormData();
            await enviarCambios(formData);
            
            await mostrarAlerta('success', 'Éxito', 'Los productos han sido actualizados correctamente.', 3000);
            window.location.reload();
            
        } catch (error) {
            mostrarAlerta('error', 'Error', 'No se pudieron guardar los cambios.');
        } finally {
            setIsLoadingSubmit(false);
        }
    };

    const prepararFormData = () => {
        const formData = new FormData();

        newProductos.forEach((producto, index) => {
            formData.append(`nuevosProductos[${index}].titulo`, producto.titulo);
            formData.append(`nuevosProductos[${index}].precio`, producto.precio);
            if (producto.imagen) {
                formData.append(`nuevosProductos[${index}].imagen`, producto.imagen);
            }
        });

        modifiedProductos.forEach((producto, index) => {
            formData.append(`productosActualizados[${index}].id`, producto.id);
            formData.append(`productosActualizados[${index}].titulo`, producto.titulo);
            formData.append(`productosActualizados[${index}].precio`, producto.precio);
            if (producto.imagen) {
                formData.append(`productosActualizados[${index}].imagen`, producto.imagen);
            }
        });

        deletedProductos.forEach(id => formData.append('productosAEliminar', id));

        return formData;
    };

    const enviarCambios = async (formData) => {
        await axios.post(
            `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/actualizarProductos`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    };

    const mostrarAlerta = (tipo, titulo, descripcion, duracion) => {
        return Alert({ tipo, titulo, descripcion, duracion });
    };

    const renderProducto = (producto, index) => (
        <div key={producto.id} className={styles.producto}>
            <div className={styles.productImage}>
                <img src={producto.imgURL || defaultImageUrl} alt="Imagen del producto" />
                <Icon
                    icon="uil:edit"
                    width={35}
                    className={styles.editIcon}
                    onClick={() => document.getElementById(`fileInput-${index}`).click()}
                />
                <input
                    type="file"
                    id={`fileInput-${index}`}
                    style={{ display: 'none' }}
                    onChange={(e) => handleImageChange(index, e.target.files[0])}
                />
            </div>
            <label>Título:</label>
            <input
                type="text"
                value={producto.titulo}
                maxLength={12}
                onChange={(e) => handleInputChange(index, e.target.value, 'titulo')}
                disabled={producto.ventas && producto.ventas.length > 0}
                className={styles.inputProducto}
            />
            <label>Precio:</label>
            <input
                type="number"
                value={producto.precio}
                onChange={(e) => handleInputChange(index, e.target.value, 'precio')}
                disabled={producto.ventas && producto.ventas.length > 0}
                className={styles.inputProducto}
            />
            {!producto.ventas || producto.ventas.length === 0 ? (
                <button
                    className={styles.eliminarBtn}
                    onClick={() => handleDeleteProducto(index)}
                >
                    Eliminar
                </button>
            ) : (
                <span className={styles.noEditable}>No editable (tiene ventas)</span>
            )}
        </div>
    );

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <button className={styles.volver} onClick={() => window.history.back()}>
                    <Icon width={30} icon="solar:arrow-left-linear" />
                </button>
            </div>
            <div className={styles.productosCajaContainer}>
                <h2>Gestión de Productos de la Caja</h2>
                <div className={styles.fondoDescripcion}>
                    <p>Desde acá podés agregar, editar o eliminar los productos que quieras vender en cantina.</p>
                </div>

                <div className={styles.productos}>
                    {loading ? (
                        Array.from({ length: 6 }).map((_, index) => (
                            <SkeletonProductoCaja key={index} />
                        ))
                    ) : (
                        <>
                            {productos.map((producto, index) => renderProducto(producto, index))}
                            <button className={styles.agregarBtn} onClick={handleAddNewProducto}>
                                Agregar nuevo producto <Icon icon="mdi:plus-circle-outline" width={16}/>
                            </button>
                        </>
                    )}
                </div>
                <SubmitButton
                    isDisabled={!puedeSubirCambios}
                    isLoading={isLoadingSubmit}
                    onClick={handleSubmit}
                    className={styles.submitButton}
                >
                    Guardar cambios
                </SubmitButton>
            </div>
        </div>
    );
};

export default ProductosCaja;