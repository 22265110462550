import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import axios from 'axios';
import styles from './FacturaDetalle.module.scss';
import { Icon } from "@iconify/react";
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import variables from '../../Context/Variables';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';

const FacturaDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { jwt, estaAutenticado } = useAuth();
  const [factura, setFactura] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refacturando, setRefacturando] = useState(false);
  const [mensajeExito, setMensajeExito] = useState(null);

  const fetchFactura = async () => {
    try {
      const response = await axios.get(
        `${variables.API_BASE_URL}/api/facturas/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt}` }
        }
      );
      setFactura(response.data);
    } catch (err) {
      setError("Error al cargar los detalles de la factura");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!estaAutenticado || !jwt) {
      return;
    }
    fetchFactura();
  }, [id, jwt, estaAutenticado]);

  const handleRefacturar = async () => {
    setRefacturando(true);
    setError(null);
    setMensajeExito(null);
    
    try {
      const response = await axios.post(
        `${variables.API_BASE_URL}/api/facturas/${id}/reintentar`,
        {},
        { 
          headers: { Authorization: `Bearer ${jwt}` }
        }
      );
      
      setMensajeExito(response.data);
    } catch (err) {
      const errorMessage = err.response?.data || "Error al refacturar. Intente nuevamente.";
      setError(errorMessage);
    } finally {
      setRefacturando(false);
      window.location.reload();
    }
  };

  if (!estaAutenticado) return <NoPermissionPage />;
  if (loading) return <div className={styles.loading}>Cargando...</div>;
  if (!factura) return <div className={styles.error}>Factura no encontrada</div>;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button 
          className={styles.backButton} 
          onClick={() => navigate('/panel-facturas')}
        >
          <Icon icon="mdi:arrow-left" /> Volver
        </button>
        <h1>Detalles de Factura</h1>
      </div>

      <div className={styles.content}>
        <div className={styles.mainInfo}>
          <div className={styles.statusBadge}>
            <span className={`${styles.dot} ${factura.facturadoEnArca ? styles.success : styles.pending}`} />
            {factura.facturadoEnArca ? 'Facturado en ARCA' : 'No facturado en ARCA'}
          </div>
          
          <div className={styles.idSection}>
            <h2>Factura #{factura.id}</h2>
            <span className={styles.type}>{factura.tipo}</span>
          </div>
        </div>

        <div className={styles.grid}>
          <div className={styles.card}>
            <h3>Información General</h3>
            <div className={styles.cardContent}>
              <div className={styles.field}>
                <span className={styles.label}>Consumidor</span>
                <span className={styles.value}>{factura.nombreConsumidor}</span>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>Collection ID</span>
                <span className={styles.value}>{factura.collectionId}</span>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>CAE</span>
                <span className={styles.value}>{factura.cae}</span>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <h3>Fechas</h3>
            <div className={styles.cardContent}>
              <div className={styles.field}>
                <span className={styles.label}>Emisión</span>
                <span className={styles.value}>{formatDate(factura.fechaEmision)}</span>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>Vencimiento CAE</span>
                <span className={styles.value}>{formatDate(factura.fechaVtoCae)}</span>
              </div>
            </div>
          </div>

          {factura.respuestasAfip && factura.respuestasAfip.length > 0 && (
            <div className={`${styles.card} ${styles.fullWidth}`}>
              <h3>
                <Icon icon="mdi:alert-circle" className={styles.alertIcon} />
                Respuestas ARCA
              </h3>
              <div className={`${styles.cardContent} ${styles.afipResponses}`}>
                {factura.respuestasAfip.map((respuesta, index) => (
                  <div key={index} className={styles.afipResponse}>
                    <div className={styles.responseNumber}>{index + 1}</div>
                    <span>{respuesta}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className={styles.actions}>
          {factura.urlPdf && (
            <a 
              href={factura.urlPdf} 
              target="_blank" 
              rel="noopener noreferrer" 
              className={styles.pdfButton}
            >
              <Icon icon="mdi:pdf-box" /> Ver PDF
            </a>
          )}
          
          {!factura.facturadoEnArca && (
            <SubmitButton
              onClick={handleRefacturar}
              isLoading={refacturando}
              isDisabled={refacturando}
              color="#99419d"
              className={styles.refacturarButton}
            >
              <Icon icon="mdi:refresh" className={styles.buttonIcon} />
              Refacturar en ARCA
            </SubmitButton>
          )}
        </div>

        {error && (
          <div className={styles.mensajeError}>
            <Icon icon="mdi:alert-circle" />
            {error}
          </div>
        )}

        {mensajeExito && (
          <div className={styles.mensajeExito}>
            <Icon icon="mdi:check-circle" />
            {mensajeExito}
          </div>
        )}
      </div>
    </div>
  );
};

export default FacturaDetalle; 