import React, { useState, useEffect, useRef } from "react";
import styles from "./TicketScanner.module.scss";
import { QrReader, QrReaderViewFinder } from "reactjs-qr-code-reader";
import { useParams } from "react-router-dom";
import Alert from "../../Components/Alert/Alert";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../Context/Variables";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import { useAuth } from '../../Context/AuthProvider';

const EscanerDeTickets = () => {
  const { eventoId } = useParams();
  const [ultimoResultado, setUltimoResultado] = useState("No se ha escaneado aún.");
  const listaDeTicketsRef = useRef([]);
  const [cargadorVisible, setCargadorVisible] = useState(true);
  const [cuerpoVisible, setCuerpoVisible] = useState(false);
  const [registrosEscaneados, setRegistrosEscaneados] = useState([]);
  const [estaCargando, setEstaCargando] = useState(false);
  const idTicketRef = useRef(null);
  const navegar = useNavigate();
  const ultimoEscaneoRef = useRef(Date.now());
  const { estaAutenticado, jwt } = useAuth();
  const buscoTickets = useRef(false);
  const [mostrarTodosLosRegistros, setMostrarTodosLosRegistros] = useState(false);

  // Create refs for the audio elements
  const sonidoExito = useRef(new Audio('https://simplepassbucket.s3.sa-east-1.amazonaws.com/sounds/446114__justinvoke__confirm-jingle.wav'));
  const sonidoError = useRef(new Audio('https://simplepassbucket.s3.sa-east-1.amazonaws.com/sounds/445978__breviceps__error-signal-2.wav'));

  // Preload sounds when component mounts
  useEffect(() => {
    sonidoExito.current.load();
    sonidoError.current.load();
  }, []);

  // Verificar tickets pendientes cada minuto
  useEffect(() => {
    const intervalo = setInterval(verificarTicketsPendientes, 60000);
    return () => clearInterval(intervalo);
  }, []);

  // Verificar tickets pendientes
  const verificarTicketsPendientes = async () => {
    console.log("Se ejecuta la verificacion");
    const ticketsGuardados = localStorage.getItem(`ticketsEnProceso-${eventoId}`);

    console.log("ticketsGuardados", ticketsGuardados);

    const ticketsEnProceso = ticketsGuardados ? JSON.parse(ticketsGuardados) : [];

    console.log("Tickets en proceso:", ticketsEnProceso.length);

    const fallidos = ticketsEnProceso.filter(t => t.estado === 'FALLIDO');

    console.log("Tickets fallidos:", fallidos.length);

    for (const ticket of fallidos) {
      try {
        // Cambia el método de invalidación según el tipo de ticket
        if (ticket.esButaca) {
          await invalidarButacaAsync(ticket.id, ticket.codigoQr, ticket.idFront);
        } else if (ticket.esCarnet) {
          await invalidarTicketAsync(ticket.id, ticket.codigoQr, ticket.idFront);
        } else {
          await invalidarTicketAsync(ticket.id, ticket.codigoQr, ticket.idFront);
        }
      } catch (error) {
        console.error("Error al procesar ticket fallido:", ticket, error);
      }
    }
  };

  // Generar ID único
  const generarIdUnico = () => {
    return Math.random().toString(36).substring(2, 18);
  };

  // useEffect para cuando se renderiza el componente
  useEffect(() => {
    document.title = "Escáner de tickets";
    window.scrollTo(0, 0);

    if (estaAutenticado) {
      obtenerTickets(eventoId);

      const intervaloId = setInterval(() => {
        obtenerTickets(eventoId);
      }, 60000);

      return () => {
        clearInterval(intervaloId);
      };
    }
  }, [eventoId, estaAutenticado]);

  function obtenerTickets(eventoId) {
    const detallesUsuario = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!detallesUsuario) {
      navegar("/login");
    }
    const detalles = JSON.parse(detallesUsuario);
    if (!detalles || !detalles.jwt) {
      navegar("/login");
    } else {
      const token = detalles.jwt;

      fetch(variables.API_BASE_URL + `/api/tickets/validateUserAndListTickets/${eventoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Respuesta de red no fue ok');
          }
          return response.json();
        })
        .then((nuevosTickets) => {
          const ticketsGuardados = localStorage.getItem(`ticketsEnProceso-${eventoId}`);
          const ticketsEnProceso = ticketsGuardados ? JSON.parse(ticketsGuardados) : [];

          // Filtra los nuevos tickets excluyendo los que ya están en proceso
          const idsEnProceso = ticketsEnProceso.map(t => t.id);
          const ticketsFiltrados = nuevosTickets.filter(ticket => !idsEnProceso.includes(ticket.id));

          // Combina tickets en proceso y nuevos tickets filtrados
          const listaActualizada = [...ticketsEnProceso, ...ticketsFiltrados];

          // Actualiza el estado o referencia de tickets
          listaDeTicketsRef.current = listaActualizada;
          console.log("CANTIDAD DE TICKETS TRAÍDOS: " + nuevosTickets.length);

          setCargadorVisible(false);
          setCuerpoVisible(true);
        })
        .catch((error) => console.error("Error fetching tickets:", error));
    }
  }

  const validarTicketPorId = (eventoId, idTicket) => {
    setEstaCargando(true);
    const detallesUsuario = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!detallesUsuario) {
      navegar("/login");
    }

    const detalles = JSON.parse(detallesUsuario);
    const token = detalles.jwt;
    fetch(
      variables.API_BASE_URL + `/api/tickets/validateById?eventoId=${eventoId}&ticketId=${idTicket}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(async response => {
        const data = await response.json();
        const ahoraEnArgentina = moment.tz("America/Argentina/Buenos_Aires");
        const horaActualizada = ahoraEnArgentina.format("HH:mm");

        if (!response.ok) {
          mostrarAlerta(data[0], "warning");
          throw new Error(data[0] || `HTTP error! Status: ${response.status}`);
        }
        if (response.ok){
          actualizarRegistrosEscaneo(idTicket, "Válido", horaActualizada);
          mostrarAlerta(data[0], "success");
        }
        return data;
      })
      .then(data => {
        const indiceTicket = listaDeTicketsRef.current.findIndex(ticket => ticket.id === idTicket);
        if (indiceTicket !== -1) {
          const ticketActualizado = { ...listaDeTicketsRef.current[indiceTicket], ...data };
          const listaTicketsActualizada = [...listaDeTicketsRef.current];
          listaTicketsActualizada[indiceTicket] = ticketActualizado;
          listaDeTicketsRef.current = listaTicketsActualizada;
        }
      })
      .catch(error => {
        console.error("Error al validar el ticket:", error);
        mostrarAlerta(error.message, "warning");
      })
      .finally(() => {
        setEstaCargando(false);
      });
  };

  const manejoDeValidacionPorId = (e) => {
    e.preventDefault();
    if (idTicketRef.current && idTicketRef.current.value) {
      validarTicketPorId(eventoId, idTicketRef.current.value);
    }
  };

  function manejarError() {
    console.log("Error al escanear");
  }

  const manejarEscaneo = (resultado) => {
    if (resultado && (Date.now() - ultimoEscaneoRef.current >= 2000)) {//cambiado a 2 seg
      ultimoEscaneoRef.current = Date.now();
      const codigoQr = resultado.text;
      validarTicketLadoCliente(codigoQr);
    }
  };

  const validarTicketLadoCliente = (codigoQr) => {
    const listaDeTickets = listaDeTicketsRef.current;
    const indiceTicket = listaDeTickets.findIndex(ticket => ticket.codigoQr === codigoQr);

    if (indiceTicket === -1) {
      sonidoError.current.play()
      setUltimoResultado("Ticket no encontrado o no es para este evento.");
      actualizarRegistrosEscaneo(codigoQr.slice(-4), "No encontrado o no válido", "N/A");
      mostrarAlerta("Ticket no encontrado o no es para este evento.", "error");
      return;
    }

    const ticket = listaDeTickets[indiceTicket];
    console.log(ticket);
    if (!ticket.esValido) {
      sonidoError.current.play()
      setUltimoResultado("Este ticket ya ha sido usado.");

      const usadoEn = ticket.fechaYHoraUsado ? moment(ticket.fechaYHoraUsado) : moment();
      console.log("FECHA " + ticket.fechaYHoraUsado);
      const usadoEnFormateado = usadoEn.format("HH:mm");
      const diff = moment().diff(usadoEn);
      const duracion = moment.duration(diff);

      const haceCuanto = `${duracion.hours()}h ${duracion.minutes()}min`;

      const horaDeUso = ticket.fechaYHoraUsado ? `a las ${usadoEnFormateado}` : "N/A";
      actualizarRegistrosEscaneo(ticket.id, `Ya utilizado hace ${haceCuanto}`, usadoEnFormateado);
      setUltimoResultado(`Este ticket ya ha sido usado. Último uso: ${horaDeUso}`);
      mostrarAlerta("Ticket ya utilizado.", "warning");
      return;
    }

    const idFront = generarIdUnico();

    if (ticket.esButaca) {
      sonidoExito.current.play()
      listaDeTicketsRef.current[indiceTicket] = { ...ticket, esValido: false };
      const ticketEnProceso = { ...ticket, idFront, estado: 'PROCESANDO' };

      const ticketsGuardados = localStorage.getItem(`ticketsEnProceso-${eventoId}`);
      const ticketsEnProceso = ticketsGuardados ? JSON.parse(ticketsGuardados) : [];
      ticketsEnProceso.push(ticketEnProceso);
      localStorage.setItem(`ticketsEnProceso-${eventoId}`, JSON.stringify(ticketsEnProceso));

      setUltimoResultado(`Butaca ${ticket.id.slice(0, 7)}...: Válida. Puede ingresar.`);
      actualizarRegistrosEscaneo(ticket.id.slice(0, 7) + "...", "Válido", obtenerHoraActualEnArgentina());
      mostrarAlerta("Butaca válida. Puede ingresar.", "success");

      invalidarButacaAsync(ticket.id, ticket.codigoQr, idFront);
    } else if (ticket.esCarnet) {
      const maxUsos = ticket.maxUsosCarnet || 0;
      const vecesUsado = ticket.vecesUsadoCarnet || 0;
      const ultimoUso = ticket.fechaYHoraUsado ? moment(ticket.fechaYHoraUsado) : moment();
      const ahora = moment().tz("America/Argentina/Buenos_Aires");
      const horasDesdeUltimoUso = ahora.diff(ultimoUso, 'hours');
      const usadoEnFormateado = ultimoUso.format("HH:mm");
      
      if (vecesUsado >= maxUsos) {
        sonidoError.current.play()
        console.log("FECHA " + ticket.fechaYHoraUsado);
        setUltimoResultado("El carnet ha alcanzado el máximo de usos.");
        actualizarRegistrosEscaneo(ticket.id, "Carnet sin usos restantes", usadoEnFormateado);
        mostrarAlerta("El carnet ha alcanzado el máximo de usos.", "error");
        return;
      }else if (ticket.fechaYHoraUsado && horasDesdeUltimoUso < 12 ) {
        sonidoError.current.play()
        const proximoUsoPermitido = ultimoUso.add(12, 'hours');
        const tiempoRestante = proximoUsoPermitido.diff(ahora, 'hours');
        setUltimoResultado(`El carnet no puede ser usado aún. Debe esperar ${tiempoRestante} horas más.`);
        actualizarRegistrosEscaneo(
          ticket.id,
          `Uso denegado - Esperar ${tiempoRestante}h más`,
          ultimoUso.format("HH:mm")
        );
        mostrarAlerta(`El carnet no puede ser usado aún. Debe esperar ${tiempoRestante} horas más.`, "error");
        return;
      }else {
        sonidoExito.current.play()
        const horaActual=obtenerHoraActualEnArgentina();
        const usosRestantes = maxUsos - (vecesUsado + 1);
        listaDeTicketsRef.current[indiceTicket] = {...ticket,vecesUsadoCarnet: vecesUsado + 1,fechaYHoraUsado: horaActual};
        const ticketEnProceso = { ...ticket, idFront, estado: 'PROCESANDO' };

        const ticketsGuardados = localStorage.getItem(`ticketsEnProceso-${eventoId}`);
        const ticketsEnProceso = ticketsGuardados ? JSON.parse(ticketsGuardados) : [];
        ticketsEnProceso.push(ticketEnProceso);
        localStorage.setItem(`ticketsEnProceso-${eventoId}`, JSON.stringify(ticketsEnProceso));

        setUltimoResultado(`Carnet válido. Usos restantes: ${usosRestantes}`);
        actualizarRegistrosEscaneo(ticket.id, `Carnet válido. Usos restantes: ${usosRestantes}`, obtenerHoraActualEnArgentina(true));
        mostrarAlerta(`Carnet válido. Usos restantes: ${usosRestantes}`, "success");

        invalidarTicketAsync(ticket.id, ticket.codigoQr, idFront);
      }
    } else {
      sonidoExito.current.play()
      listaDeTicketsRef.current[indiceTicket] = { ...ticket, esValido: false, fechaYHoraUsado: obtenerHoraActualEnArgentina() };
      const ticketEnProceso = { ...ticket, idFront, estado: 'PROCESANDO' };

      const ticketsGuardados = localStorage.getItem(`ticketsEnProceso-${eventoId}`);
      const ticketsEnProceso = ticketsGuardados ? JSON.parse(ticketsGuardados) : [];
      ticketsEnProceso.push(ticketEnProceso);
      localStorage.setItem(`ticketsEnProceso-${eventoId}`, JSON.stringify(ticketsEnProceso));

      setUltimoResultado(`Ticket ${ticket.id}: Válido. Puede ingresar.`);
      actualizarRegistrosEscaneo(ticket.id, "Válido", obtenerHoraActualEnArgentina(true));
      mostrarAlerta("Ticket válido. Puede ingresar.", "success");

      invalidarTicketAsync(ticket.id, ticket.codigoQr, idFront);
    }
  };

  const actualizarRegistrosEscaneo = (idTicket, resultado, usadoEnFormateado) => {
    setRegistrosEscaneados(registrosActuales => [{ idTicket, resultado, usadoEn: usadoEnFormateado }, ...registrosActuales]);
  };

  const obtenerHoraActualEnArgentina = (corta) => {
    if (corta) {
      const ahoraEnArgentina = moment.tz("America/Argentina/Buenos_Aires");
      return ahoraEnArgentina.format("HH:mm");
    }
    const ahoraEnArgentina = moment.tz("America/Argentina/Buenos_Aires");
    return ahoraEnArgentina.format("YYYY-MM-DDTHH:mm:ss");
  };

  const invalidarTicketAsync = async (idTicket, codigoQr, idFront) => {
    const detallesUsuario = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!detallesUsuario) {
      navegar("/login");
      return;
    }

    const detalles = JSON.parse(detallesUsuario);
    const token = detalles.jwt;

    const cuerpoSolicitud = {
      idTicket: idTicket.toString(),
      eventoId: eventoId,
      codigoQr: codigoQr,
    };

    try {
      const response = await fetch(variables.API_BASE_URL + `/api/tickets/invalidarTicketOCarnet`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cuerpoSolicitud),
      });

      if (response.status === 200) {
        const ticketsGuardados = JSON.parse(localStorage.getItem(`ticketsEnProceso-${eventoId}`)) || [];
        const ticketsActualizados = ticketsGuardados.filter(t => t.idFront !== idFront);
        localStorage.setItem(`ticketsEnProceso-${eventoId}`, JSON.stringify(ticketsActualizados));
      } else {
        throw new Error('Error al invalidar el ticket');
      }

    } catch (error) {
      console.error("Error al invalidar el ticket", error);
      const ticketsGuardados = JSON.parse(localStorage.getItem(`ticketsEnProceso-${eventoId}`)) || [];
      const ticketsActualizados = ticketsGuardados.map(t =>
        t.idFront === idFront ? { ...t, estado: 'FALLIDO' } : t
      );
      localStorage.setItem(`ticketsEnProceso-${eventoId}`, JSON.stringify(ticketsActualizados));
    }
  };

  const invalidarButacaAsync = async (idTicket, codigoQr, idFront) => {
    const detallesUsuario = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!detallesUsuario) {
      navegar("/login");
      return;
    }

    const detalles = JSON.parse(detallesUsuario);
    const token = detalles.jwt;

    const cuerpoSolicitud = {
      idTicket: idTicket,
      eventoId: eventoId,
      codigoQr: codigoQr
    };

    try {
      const response = await fetch(variables.API_BASE_URL + `/api/butacas/invalidarButaca`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cuerpoSolicitud),
      });

      if (response.status === 200) {
        const ticketsGuardados = JSON.parse(localStorage.getItem(`ticketsEnProceso-${eventoId}`)) || [];
        const ticketsActualizados = ticketsGuardados.filter(t => t.idFront !== idFront);
        localStorage.setItem(`ticketsEnProceso-${eventoId}`, JSON.stringify(ticketsActualizados));
      } else {
        throw new Error('Error al invalidar la butaca');
      }
    } catch (error) {
      console.error("Error al invalidar la butaca", error);
      const ticketsGuardados = JSON.parse(localStorage.getItem(`ticketsEnProceso-${eventoId}`)) || [];
      const ticketsActualizados = ticketsGuardados.map(t =>
        t.idFront === idFront ? { ...t, estado: 'FALLIDO' } : t
      );
      localStorage.setItem(`ticketsEnProceso-${eventoId}`, JSON.stringify(ticketsActualizados));
    }
  };

  const mostrarAlerta = (titulo, tipo) => {
    Alert({
      tipo: tipo,
      titulo: titulo,
      descripcion: '',
      duracion: 4000
    });
  };

  return (
    <section className={styles.bodyScanner}>
      <div
        id="loaderContainer" className={styles.loaderContainer}
        style={{ display: cargadorVisible ? "flex" : "none" }}
      >
        <div className={styles.loaderCargandoScanner}>
          <div className={styles.box1}></div>
          <div className={styles.box2}></div>
          <div className={styles.box3}></div>
        </div>
        <p className={styles.cargandoCartel} id="cargandoCartel">Cargando scanner...</p>
      </div>

      <div style={{ display: cuerpoVisible ? "flex" : "none" }} className={styles.sectionScanner} id="sectionScanner">
        <div className={styles.scannerContainer}>
          <h2 className={styles.tituloScan}>
            ESCÁNER DE TICKETS <Icon width={20} icon="material-symbols:qr-code-scanner-rounded" />
          </h2>

          <QrReader
            className={styles.video}
            onRead={manejarEscaneo}
            read={true}
            onReadError={manejarError}
          >
            <QrReaderViewFinder style={{ color: "#862f8b", border: "0px" }} />
          </QrReader>
          
          <div className={`${styles.result} ${ultimoResultado.startsWith("Carnet") ? styles.resultCarnet : ''}`}>
            <h2>Último resultado</h2>
            <div style={ultimoResultado.startsWith("Carnet") ? {display:"block"}:{display:"none"}}><Icon width={40} icon="solar:user-id-outline" /></div>
            <p>{ultimoResultado}</p>
          </div>

          <div className={styles.scannerRecords}>
            <h2 className={styles.tituloRegistros}>Últimos registros <Icon icon="solar:clipboard-list-linear" /></h2>
            <table>
              <thead>
                <tr>
                  <th>ID del Ticket</th>
                  <th>Resultado</th>
                  <th>Horario usado</th>
                </tr>
              </thead>
              <tbody>
                {registrosEscaneados.length > 0 ? (
                  (mostrarTodosLosRegistros ? registrosEscaneados : registrosEscaneados.slice(0, 10)).map((record, index) => (
                    <tr key={index}>
                      <td>{record.idTicket}</td>
                      <td>{record.resultado}</td>
                      <td>{record.usadoEn || 'No dice'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No hay registro de escaneos aún</td>
                  </tr>
                )}
              </tbody>
            </table>
            {registrosEscaneados.length > 10 && (
              <button 
                className={styles.verMasBtn}
                onClick={() => setMostrarTodosLosRegistros(!mostrarTodosLosRegistros)}
              >
                {mostrarTodosLosRegistros ? 'Ver menos' : 'Ver más'}
              </button>
            )}
          </div>

          <div className={styles.validarTicketId}>
            <h2 className={styles.tituloScan}>Validar Ticket por ID</h2>
            <p>Ingresar el número de ID que se encuentra en la parte de arriba del ticket</p>
            <form onSubmit={manejoDeValidacionPorId}>
              <input
                type="number"
                ref={idTicketRef}
                placeholder="Ingresa el ID del ticket"
                required
              />
              <SubmitButton
                isDisabled={estaCargando}
                color="#c970ce"
                isLoading={estaCargando}
                onClick={() => { }}
              >
                Validar
              </SubmitButton>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EscanerDeTickets;