import React, { useState, useEffect, useRef } from "react";
import styles from "../scanner-ticket/TicketScanner.module.scss";
import { QrReader, QrReaderViewFinder } from "reactjs-qr-code-reader";
import { useParams } from "react-router-dom";
import Alert from "../../Components/Alert/Alert";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../Context/Variables";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import { Icon } from "@iconify/react";

const ConsumicionScanner = () => {
  const { eventoId } = useParams();
  const [lastResult, setLastResult] = useState("No se ha escaneado aún.");
  const ticketsListRef = useRef([]);
  const [jwt, setJwt] = useState("");
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [isBodyVisible, setIsBodyVisible] = useState(false);
  const [scanRecords, setScanRecords] = useState([]);
  const [puedeLeer, setPuedeLeer] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const ticketIdRef = useRef(null);
  const navigate = useNavigate();
  const ultimoEscaneoRef = useRef(Date.now());
  const sonidoExito = useRef(new Audio('https://simplepassbucket.s3.sa-east-1.amazonaws.com/sounds/446114__justinvoke__confirm-jingle.wav'));
  const sonidoError = useRef(new Audio('https://simplepassbucket.s3.sa-east-1.amazonaws.com/sounds/445978__breviceps__error-signal-2.wav'));

  //useEffecto para subir a lo alto de la pagina en el inicio
  useEffect(() => {
    document.title = "Escáner de consumiciones";
    window.scrollTo(0, 0);
    ensureAuthenticated()
  }, []);

  const ensureAuthenticated = () => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);
      setJwt(details.jwt);
      fetchTickets(eventoId);
      return details;
    }
  };


  useEffect(() => {

    fetchTickets(eventoId);

    const intervalId = setInterval(() => {
      fetchTickets(eventoId);
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventoId]);

  function fetchTickets(eventoId) {

    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    }
    const details = JSON.parse(userDetails);
    if (!details || !details.jwt) {
      navigate("/login");
    } else {
      const token = details.jwt;

      fetch(variables.API_BASE_URL + `/api/ticketConsumiciones/validateUserAndListConsumiciones?eventoId=${eventoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Respuesta de red no fue ok');
          }
          return response.json();
        })
        .then((data) => {
          ticketsListRef.current = data;
          setIsLoaderVisible(false);
          setIsBodyVisible(true);
          console.log("tickets: ");
          console.log(ticketsListRef);
        })
        .catch((error) => console.error("Error fetching tickets de consumición:", error));
    }
  }

  const validarTicketPorId = (eventoId, ticketId, jwt) => {
    setIsLoading(true);
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    }

    const details = JSON.parse(userDetails);
    const token = details.jwt;

    fetch(variables.API_BASE_URL + `/api/ticketConsumiciones/validateTicketConsumicionById?eventoId=${eventoId}&ticketConsumicionId=${ticketId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data[0] || `HTTP error! Status: ${response.status}`);
        }
        return data;
      })
      .then(data => {
        showAlert(data[0], data[0].includes("Ticket de consumición válido") ? "success" : "warning");

        // Encuentra el ticket en la lista del cliente y actualízalo como inválido
        const ticketIndex = ticketsListRef.current.findIndex(ticket => ticket.id.toString() === ticketId);
        if (ticketIndex !== -1) {
          ticketsListRef.current[ticketIndex].esValido = false;
          updateScanRecords(ticketId, "Consumición válida. Puede retirar.");
        }
      })
      .catch(error => {
        console.error("Error al validar el ticket:", error);
        showAlert(error.message, "warning");
        updateScanRecords(ticketId, error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ticketIdRef.current && ticketIdRef.current.value) {
      validarTicketPorId(eventoId, ticketIdRef.current.value, jwt);
    }
  };

  function handleError() {
    console.log("sxx");
  }

  const handleScan = (result) => {
    if (result && puedeLeer && (Date.now() - ultimoEscaneoRef.current >= 4000)) {
      ultimoEscaneoRef.current = Date.now();
      const codigoQr = result.text;
      console.log(ticketsListRef);
      validateTicketClientSide(codigoQr);
    }
  };

  const validateTicketClientSide = (codigoQr) => {
    const ticketsList = ticketsListRef.current;
    const ticketIndex = ticketsList.findIndex(ticket => ticket.codigoQr === codigoQr);

    if (ticketIndex === -1) {
      sonidoError.current.play();
      setLastResult("Consumición no encontrada o no es para este evento.");
      updateScanRecords(codigoQr.slice(-4), "No encontrada o no válida", "N/A", "");
      showAlert("Consumición no encontrada o no es para este evento.", "error");
      return;
    }

    const ticket = ticketsList[ticketIndex];
    if (ticket.esValido) {
      sonidoExito.current.play();
      const now = moment().format();
      ticketsListRef.current[ticketIndex] = { ...ticket, esValido: false, fechaYHoraUsado: now };

      setLastResult(`Ticket ${ticket.id}: Válido. Puede retirar.`);
      updateScanRecords(ticket.id, "Válido", '', now);
      showAlert("Consumición válida. Puede retirar.", "success");

      invalidateTicketAsync(ticket.id);
    } else {
      sonidoError.current.play();
      const timeAgo = moment(ticket.fechaYHoraUsado).fromNow();
      setLastResult(`Ticket ${ticket.id}: Ya utilizado hace ${timeAgo}.`);
      // Pasamos la fecha y hora de uso para ser formateadas y mostradas
      updateScanRecords(ticket.id, "Ya utilizado", timeAgo, ticket.fechaYHoraUsado);
      showAlert("Consumición ya retirada.", "warning");
    }
  };

  const updateScanRecords = (ticketId, result, timeAgo = '', usedAt = '') => {
    const formattedUsedAt = usedAt ? moment(usedAt).format("HH:mm") : 'No disponible';
    setScanRecords(currentRecords => [{ ticketId, result, usedAt: formattedUsedAt }, ...currentRecords]);
  };

  const invalidateTicketAsync = (id) => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    }

    const details = JSON.parse(userDetails);
    const token = details.jwt;

    fetch(variables.API_BASE_URL + `/api/ticketConsumiciones/invalidateTicketConsumicionAsync?id=${id}`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al invalidar el ticket de consumición');
        }
        return response.json();
      })
      .then(data => {
        console.log("Respuesta:", data);
        showAlert("Ticket de consumición actualizado exitosamente.", "success");
      })
      .catch(error => {
        console.error("Error al invalidar el ticket de consumición", error);
      });
  };

  const showAlert = (titulo, tipo) => {
    Alert({
      tipo: tipo,
      titulo: titulo,
      descripcion: '',
      duracion: 3000
    });
  };

  // Precargar sonidos cuando el componente se monta
  useEffect(() => {
    sonidoExito.current.load();
    sonidoError.current.load();
  }, []);

  return (
    <section className={styles.bodyScanner}>

      <div
        id="loaderContainer" className={styles.loaderContainer}
        style={{ display: isLoaderVisible ? "flex" : "none" }}
      >
        <div className={styles.loaderCargandoScanner}>
          <div className={styles.box1}></div>
          <div className={styles.box2}></div>
          <div className={styles.box3}></div>
        </div>
        <p className={styles.cargandoCartel} id="cargandoCartel">Cargando scanner...</p>
      </div>

      <div style={{ display: isBodyVisible ? "flex" : "none" }} className={styles.sectionScanner} id="sectionScanner">
        <h2 className={styles.tituloScan}>
          Escáner de consumiciones <Icon icon="solar:bottle-broken" />
        </h2>
        <div className={styles.scannerContainer}>
          <QrReader
            className={styles.video}
            onRead={handleScan}
            read={puedeLeer}
            onReadError={handleError}
          >
            <QrReaderViewFinder style={{ color: "#862f8b", border: "0px" }} />
          </QrReader>
          <div className={styles.result}>
            <h2>Último resultado:</h2>
            <p>{lastResult}</p>
          </div>

          <div className={styles.scannerRecords}>
            <h3>Registro de resultados</h3>
            <table>
              <thead>
                <tr>
                  <th>ID del Ticket</th>
                  <th>Resultado</th>
                  <th>Horario usado</th>
                </tr>
              </thead>
              <tbody>
                {scanRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{record.ticketId}</td>
                    <td>{record.result}</td>
                    <td>{record.usedAt || 'No dice'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>

        {/* Resto del componente... */}
        <div className={styles.validarTicketId}>
          <h2>Validar Ticket por ID</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="number"
              ref={ticketIdRef}
              placeholder="Ingresa el ID del ticket"
              required
            />
            <SubmitButton
              isDisabled={isLoading}
              color="#c970ce"
              isLoading={isLoading}
              onClick={() => { }}
            >
              Validar
            </SubmitButton>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ConsumicionScanner;
