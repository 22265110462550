import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import "../Ventas ult. 3 meses/GraficoSkeleton.scss";
import variables from "../../../Context/Variables";
import styles from "./Totales6MesesGrafico.module.scss";
import "../Grafico de barras/Skeleton/GraficoDeBarraSkeleton.scss";

const coloresEmpresa = ["rgb(154, 66, 158)", "#862f8b", "#c970ce"];

const Totales6MesesGrafico = ({ jwt }) => {
  const [data, setData] = useState([]);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const fetchTotales = async () => {
      setCargando(true);
      try {
        const response = await fetch(
          variables.API_BASE_URL + "/api/pagos/totales-ultimos-6-meses",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwt}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok)
          throw new Error("Error al cargar los totales de los últimos 6 meses");

        const jsonData = await response.json();
        const transformedData = jsonData.map((item) => ({
          mes: item.mes,
          "Total Ventas": item.total,
        }));

        setData(transformedData);
        setCargando(false);
      } catch (error) {
        console.error("Error al obtener los totales:", error);
        setCargando(false);
      }
    };

    fetchTotales();
  }, [jwt]);

  if (cargando) {
    return (
      <div className="skeleton-chart">
        <div className="bar"></div>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
      </div>
    );
  }

  return (
    <div className={styles.contGrafico}>
      <h3>Ventas por mes <span>(últimos 6 meses)</span></h3>
      <ResponsiveBar
        data={data}
        keys={["Total Ventas"]}
        indexBy="mes"
        margin={{ top: 20, right: 20, bottom: 50, left: 65 }}
        padding={0.2}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={coloresEmpresa}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 4,
          tickPadding: 4,
          tickRotation: 0
        }}
        axisLeft={{
          tickSize: 4,
          tickPadding: 4,
          tickRotation: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

export default Totales6MesesGrafico; 