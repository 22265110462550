import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './PagosLista.module.scss';
import variables from '../../Context/Variables';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';

const PagosLista = () => {
    const [pagos, setPagos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0); 
    const [error, setError] = useState('');
    const { estaAutenticado, jwt, logout } = useAuth();
    const abortControllerRef = useRef(null);

    const cancelPreviousRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(value);
    };

    const fetchPagos = async (jwt, page = 0, size = 15) => {
        cancelPreviousRequest();
        try {
            setLoading(true);
            const response = await axios.get(
                `${variables.API_BASE_URL}/api/pagos/todos?page=${page}&size=${size}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                    signal: abortControllerRef.current.signal,
                }
            );
            setPagos(response.data); // Actualiza la lista de pagos
            setLoading(false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request cancelled');
            } else {
                console.error('Error fetching pagos', error);
                setError('Failed to fetch pagos.');
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = "Admin Panel";
        if (!estaAutenticado) {
            return;
        }
        window.scroll(0, 0);
        fetchPagos(jwt, currentPage); // Obtener pagos al montar o cambiar de página
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [estaAutenticado, jwt, currentPage]); // Refresca al cambiar jwt o página actual

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0)); // Evita valores negativos
    };

    const handleLogout = () => {
        logout();
    };

    const handleFacturar = (pagoId) => {
        console.log(`Facturar pago con ID: ${pagoId}`);
    };

    if (!estaAutenticado) {
        return <NoPermissionPage />;
    }

    return (
        <div className={styles.bodyGR}>
            <div className={styles.header}>
                <h2 className={styles.titulo}>Panel de Administración</h2>
            </div>
            <div className={styles.pagosTabla}>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Collection ID</th>
                            <th>Consumidor</th>
                            <th>DNI</th>
                            <th>Subtotal</th>
                            <th>Total</th>
                            <th>Facturado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8">Cargando...</td>
                            </tr>
                        ) : (
                            pagos.map((pago) => (
                                <tr key={pago.id}>
                                    <td>{pago.id}</td>
                                    <td>{pago.collectionId || 'No disponible'}</td>
                                    <td>{pago.username || 'No disponible'}</td>
                                    <td>{pago.dni || 'No disponible'}</td>
                                    <td>{formatCurrency(pago.subtotal || 0)}</td>
                                    <td>{formatCurrency(pago.total || 0)}</td>
                                    <td>{pago.facturado ? 'Sí' : 'No'}</td>
                                    <td>
                                        <button onClick={() => handleFacturar(pago.id)}>Facturar</button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {error && <p className="error">{error}</p>}
            <div className={styles.pagination}>
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 0}
                >
                    Anterior
                </button>
                <span>Página {currentPage + 1}</span>
                <button
                    onClick={handleNextPage}
                    disabled={pagos.length < 15}
                >
                    Siguiente
                </button>
            </div>
        </div>
    );
};

export default PagosLista;