import React, { useState, useEffect, useMemo } from 'react';
import styles from "./MapaCarnavalesHasenkamp.module.scss";
import { Icon } from '@iconify/react';

const formatCurrency = (value) => {
  return new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);
};

const MapaCarnavalesHasenkamp = ({ evento, onSeatsSelected }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const tiposDeTickets = useMemo(() => {
    return evento.tiposDeTickets.filter(ticket => ticket.fila && ticket.numeroAsiento);
  }, [evento.tiposDeTickets]);

  useEffect(() => {
    if (onSeatsSelected) {
      const selectedSeatsDetails = selectedSeats.map((seatId) => {
        const seat = tiposDeTickets.find((ticket) => ticket.id === seatId);
        return {
          id: seat.id,
          nombre: seat.nombre,
          precio: seat.precio,
          fila: seat.fila,
          numeroAsiento: seat.numeroAsiento,
          seccion: seat.seccion,
        };
      });
      onSeatsSelected(selectedSeatsDetails);
    }
  }, [selectedSeats, tiposDeTickets, onSeatsSelected]);

  const handleSectionClick = (sectionName) => {
    setSelectedSection(sectionName);
    setModalIsOpen(true);
  };

  const toggleSeatSelection = (ticketId) => {
    setSelectedSeats((prevSelectedSeats) => {
      if (prevSelectedSeats.includes(ticketId)) {
        return prevSelectedSeats.filter(id => id !== ticketId);
      } else {
        return [...prevSelectedSeats, ticketId];
      }
    });
  };

  const removeSeatSelection = (ticketId) => {
    setSelectedSeats((prevSelectedSeats) =>
      prevSelectedSeats.filter((id) => id !== ticketId)
    );
  };

  const groupedByRow = useMemo(() => {
    const grouped = tiposDeTickets.reduce((acc, ticket) => {
      if (!acc[ticket.fila]) {
        acc[ticket.fila] = [];
      }
      acc[ticket.fila].push(ticket);
      return acc;
    }, {});
    Object.keys(grouped).forEach(fila => {
      grouped[fila].sort((a, b) => a.numeroAsiento - b.numeroAsiento);
    });
    return grouped;
  }, [tiposDeTickets]);

  const getSeatColor = (ticket) => {
    if (ticket.estado === 'VENDIDA') return 'red';
    if (ticket.estado === 'RESERVADA') return 'orange';
    if (selectedSeats.includes(ticket.id)) return 'green';
    return '#ccc';
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className={styles.eventContainer}>
      <h3 className={styles.auspiciado}>Auspicia este evento</h3>
      <img className={styles.logoBER} src="https://simplepassbucket.s3.amazonaws.com/img/eventos/eventImage_20250107_225133_197_28505943.png" alt="Logo BER" />

      <div className={styles.descripcion}>
        <p className={styles.precios}>Precios:</p>

        <p className={styles.item}>Palco (mesa para 5 personas): <span>Desde {formatCurrency(30000)}</span></p>
        <p className={styles.item}>Fila 1 en platea: <span>{formatCurrency(7000)}</span></p>
        <p className={styles.item}>Fila 2 en platea: <span>{formatCurrency(5000)}</span></p>
        <p className={styles.item}>Fila 3 en platea: <span>{formatCurrency(3000)}</span></p>
      </div>
      <div className={styles.mapContainer}>
        <div className={styles.mobileScroll}>
          Deslice para ver más <Icon width={20} icon="icon-park-outline:move" />
        </div>
        <div className={styles.scrollContainer}>
          <div className={styles.mapLayout}>
            {/* Fila Superior */}
            <div className={styles.row}>
              <div className={`${styles.section} ${styles.sillones} ${styles.free}`}>
                SILLONES (GRATIS)
              </div>
              <div
                className={`${styles.section} ${styles.palcoAzul}`}
                onClick={() => handleSectionClick('PALCO AZUL')}
              >
                PALCO AZUL
              </div>
              <div
                className={`${styles.section} ${styles.plateaVerde} ${styles.platea}`}
                onClick={() => handleSectionClick('PLATEA VERDE')}
              >
                PLATEA VERDE
              </div>
              <div className={`${styles.section} ${styles.escenario}`}>
                ESCENARIO
              </div>
              <div className={`${styles.section} ${styles.tribuna} ${styles.free}`}>
                TRIBUNA (GRATIS)
              </div>
              <div
                className={`${styles.section} ${styles.plateaRoja} ${styles.platea}`}
                onClick={() => handleSectionClick('PLATEA ROJA')}
              >
                PLATEA ROJA
              </div>
            </div>

            <div className={styles.pasarela}>
              <span>→ → PASARELA → →</span>
            </div>

            {/* Fila Inferior */}
            <div className={styles.row}>
              <div className={`${styles.section} ${styles.sillones} ${styles.free}`}>
                SILLONES (GRATIS)
              </div>
              <div
                className={`${styles.section} ${styles.palcoBlanco}`}
                onClick={() => handleSectionClick('PALCO BLANCO')}
              >
                PALCO BLANCO
              </div>
              <div
                className={`${styles.section} ${styles.plateaNegra} ${styles.platea}`}
                onClick={() => handleSectionClick('PLATEA NEGRA')}
              >
                PLATEA NEGRA
              </div>
              <div className={`${styles.section} ${styles.tribunaGrande} ${styles.free}`}>
                TRIBUNA (GRATIS)
              </div>
              <div
                className={`${styles.section} ${styles.plateaNaranja}`}
                onClick={() => handleSectionClick('TRIBUNA NARANJA')}
              >
                TRIBUNA NARANJA
              </div>
              <div
                className={`${styles.section} ${styles.plateaDorada} ${styles.platea}`}
                onClick={() => handleSectionClick('PLATEA DORADA')}
              >
                PLATEA DORADA
              </div>
            </div>
          </div>
        </div>

        <div className={styles.selectedSeatsContainer}>
          <h4 className={styles.seleccionados}>Butacas seleccionadas:</h4>
          {selectedSeats.map((seatId) => {
            const seat = tiposDeTickets.find((ticket) => ticket.id === seatId);
            return (
              <div key={seat.id} className={styles.selectedSeat}>
                <span>{`${seat.nombre} - ${formatCurrency(seat.precio)}`}</span>
                <button
                  className={styles.removeButton}
                  onClick={() => removeSeatSelection(seat.id)}
                >
                  X
                </button>
              </div>
            );
          })}
        </div>

        {modalIsOpen && (
          <div className={styles.modalOverlay} onClick={closeModal}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
              <button className={styles.closeButton} onClick={closeModal}>
                ✕
              </button>

              <div className={styles.modalHeader}>
                <h2>Sección {selectedSection}</h2>
                <div className={styles.mobileScroll}>
                  Deslice para ver más <Icon width={20} icon="icon-park-outline:move" />
                </div>

              </div>

              <div className={styles.seatsContainerWrapper}>
                <div className={styles.seatsContainer} data-section={selectedSection}>
                  {Object.keys(groupedByRow)
                    .filter(fila => tiposDeTickets.find(ticket =>
                      ticket.fila === fila && ticket.seccion === selectedSection
                    ))

                    .map(fila => (
                      <div
                        key={fila}
                        className={styles.rowContainer}
                        data-section={selectedSection}
                      >
                        {groupedByRow[fila]
                          .filter(ticket => ticket.seccion === selectedSection)
                          .map(ticket => (
                            <button
                              key={ticket.id}
                              className={styles.seatButton}
                              style={{ backgroundColor: getSeatColor(ticket) }}
                              onClick={() => toggleSeatSelection(ticket.id)}
                              disabled={ticket.estado !== 'DISPONIBLE'}
                            >
                              {`${ticket.fila}-${ticket.numeroAsiento}`}
                            </button>
                          ))}
                      </div>
                    ))}
                </div>
                <div className={styles.confirmButtonContainer}>
                  <button
                    className={`${styles.confirmButton} ${selectedSeats.length > 0 ? styles.pulse : ''}`}
                    onClick={closeModal}
                    disabled={selectedSeats.length === 0}
                  >
                    Confirmar
                  </button>
                  <p className={styles.disclaimer}>
                    *Las butacas y palcos no incluyen la entrada al evento. La entrada debe adquirirse por separado.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default MapaCarnavalesHasenkamp; 