import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import styles from './TicketConsumicion.module.scss';
import { Icon } from "@iconify/react";

const TicketConsumicion = ({ ticket }) => {
  const ticketRef = useRef(null);
  const downloadButtonRef = useRef(null);

  const descargarComoImagen = () => {
    if (ticketRef.current) {
      // Ocultar el botón antes de capturar
      if (downloadButtonRef.current) {
        downloadButtonRef.current.style.display = "none";
      }

      html2canvas(ticketRef.current, { allowTaint: true, useCORS: true })
        .then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `CONSUMICION-ID:${ticket.id}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Error al descargar el ticket:', error);
        })
        .finally(() => {
          // Restaurar el botón después de capturar
          if (downloadButtonRef.current) {
            downloadButtonRef.current.style.display = "block";
          }
        });
    }
  };

  return (
    <div ref={ticketRef} className={styles.ticketConsumicionCont}>
      <div className={styles.ticketConsumicionHeader}>
        <span className={styles.ticketConsumicionTitulo}>
          Ticket de consumición <img className={styles.ticketConsumicionImg} src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Tickets/soda+(1).png" alt="" />
        </span>
      </div>
      <div className={styles.ticketConsumicionBrand}>
        <span className={styles.brandName}>simple<span className={styles.brandAccent}>pass</span></span>
      </div>
      <div className={styles.ticketConsumicionQR}>
        <img crossOrigin="anonymous" className={styles.qrCodeImg} src={`${ticket.qrImg}?nocache=${new Date().getTime()}`} alt="QR Code"/>
      </div>
      <div className={styles.ticketConsumicionId}>ID: {ticket.id}</div>
      <div className={styles.ticketConsumicionProducto}>{ticket.titulo}</div>
      <div className={styles.ticketConsumicionInfo}>
        Pasa a retirarlo con este ticket directamente, sin ir por caja.
      </div>
      <button 
        ref={downloadButtonRef}
        className={styles.btnDescargarIndividual} 
        onClick={descargarComoImagen}
      >
        Descargar <Icon icon="solar:download-minimalistic-bold" width={16} />
      </button>
    </div>
  );
};

export default TicketConsumicion;
