import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Icon } from "@iconify/react";
import styles from './UltimosProductores.module.scss';
import variables from '../../../Context/Variables';

const UltimosProductores = ({ jwt }) => {
  const [productores, setProductores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductores = async () => {
      try {
        const response = await axios.get(
          `${variables.API_BASE_URL}/api/usuarios/ultimos-productores`,
          {
            headers: { Authorization: `Bearer ${jwt}` }
          }
        );
        setProductores(response.data);
      } catch (err) {
        setError('Error al cargar los productores');
      } finally {
        setLoading(false);
      }
    };

    fetchProductores();
  }, [jwt]);

  if (loading) return <div className={styles.loading}>Cargando...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.productoresContainer}>
      <h3>
        <Icon icon="mdi:account-multiple" />
        Últimos 10 productores registrados
      </h3>
      <div className={styles.productoresList}>
        {productores.map((productor, index) => (
          <div key={index} className={styles.productorCard}>
            <div className={styles.productorIcon}>
              <Icon icon="mdi:account-circle" />
            </div>
            <div className={styles.productorInfo}>
              <span className={styles.nombre}>{productor.username}</span>
              <div className={styles.detalles}>
                <span className={styles.detalle}>
                  <Icon icon="mdi:phone" />
                  {productor.celular || 'No disponible'}
                </span>
                <span className={styles.detalle}>
                  <Icon icon="mdi:email" />
                  {productor.email}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UltimosProductores; 