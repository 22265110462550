import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Icon } from '@iconify/react';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import styles from './Cajas.module.scss';
import { Link } from 'react-router-dom';
import variables from '../../Context/Variables';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import Alert from '../../Components/Alert/Alert';

const Cajas = () => {
  const [cajas, setCajas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newCajas, setNewCajas] = useState([]);
  const { estaAutenticado, jwt } = useAuth();
  const { eventoId, nombre } = useParams();
  const [formAierto, setFormAbierto] = useState(false);
  const abortControllerRef = useRef(null);

  const [menuVisible, setMenuVisible] = useState(null);

  const toggleMenu = (index) => {
    setMenuVisible((prev) => (prev === index ? null : index));
  };

  

  const handleCheckboxChange = (index, checked) => {
    const updatedCajas = [...newCajas];
    updatedCajas[index].importar = checked;
    setNewCajas(updatedCajas);
  };
  
  const handleSeleccionCaja = (index, value) => {
    const updatedCajas = [...newCajas];
    updatedCajas[index].cajaSeleccionadaId = value; // Guardar el ID de la caja seleccionada
    setNewCajas(updatedCajas);
  };
  
  

  const cancelPreviousRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
  };

  const fetchCajas = async (jwt) => {
    try {
      const response = await axios.get(`${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        signal: abortControllerRef.current.signal,
      });

      setCajas(response.data.cajas || []);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous request cancelled');
      } else {
        console.error('Error fetching cajas', error);
        setError('Failed to fetch cajas for the event.');
        setLoading(false);

        // Mostrar Alert en caso de error
        Alert({
          tipo: 'error',
          titulo: 'Error',
          descripcion: 'No se pudo cargar las cajas del evento.',
          duracion: 3000
        });
      }
    }
  };

  const handleAddNewCaja = () => {
    setNewCajas([...newCajas, { nombre: '', isSubmitting: false }]);
    setFormAbierto(true);
  };

  const handleNombreChange = (index, value) => {
    const updatedNewCajas = [...newCajas];
    updatedNewCajas[index].nombre = value;
    setNewCajas(updatedNewCajas);
  };

  const handleSubmitCaja = async (index) => {
    const caja = newCajas[index];
    if (!caja.nombre.trim()) {
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'El nombre de la caja no puede estar vacío.',
        duracion: 3000,
      });
      return;
    }
  
    try {
      const payload = {
        nombreCaja: caja.nombre,
        cajaSeleccionadaId: caja.cajaSeleccionadaId,
      };
  
      // Incluir el ID de la caja seleccionada si está activada la opción de importar
      if (caja.importar && caja.cajaSeleccionadaId) {
        payload.cajaSeleccionadaId = caja.cajaSeleccionadaId;
      }
  
      const response = await axios.post(
        `${variables.API_BASE_URL}/api/cantina/${eventoId}/agregarCaja`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
  
      setCajas([...cajas, response.data]); // Actualizar lista de cajas
      setNewCajas(newCajas.filter((_, i) => i !== index)); // Eliminar el formulario de la caja creada
  
      Alert({
        tipo: 'success',
        titulo: 'Caja creada',
        descripcion: 'La nueva caja ha sido creada con éxito.',
        duracion: 3000,
      });
    } catch (error) {
      console.error('Error creando la caja:', error);
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'No se pudo crear la caja.:'+error,
        duracion: 3000,
      });
    }
  };
  

  useEffect(() => {
    if (!estaAutenticado) {
      return;
    }
    window.scroll(0, 0);

    cancelPreviousRequest();
    fetchCajas(jwt);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [estaAutenticado, jwt]);

  if (!estaAutenticado) {
    return <NoPermissionPage />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.cajasContainer}>
      <div className={styles.header}>
        <button className={styles.volver} onClick={() => { window.history.back() }}>
          <Icon width={30} icon="solar:arrow-left-linear" />
        </button>
      </div>
      <h2 className={styles.titulo}>Cajas de {decodeURIComponent(nombre)}</h2>
      {(cajas.length === 0 && !formAierto) && (
        <div className={styles.noCajas}>
          <p>Usted aún no posee cajas.</p>
        </div>
      )}

      <div className={styles.contCajasYAgregar}>

        <ul className={styles.listaCajas}>
          {loading ?
            <>
              <li className={styles.caja}>
                <a>
                  <div className={styles.conIconSkeleton}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>Cargando...</p>
                </a>
              </li>
              <li className={styles.caja}>
                <a>
                  <div className={styles.conIconSkeleton}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>Cargando...</p>
                </a>
              </li>
              <li className={styles.caja}>
                <a>
                  <div className={styles.conIconSkeleton}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>Cargando...</p>
                </a>
              </li>
            </>
            :
            cajas.map((caja) => (
              <li key={caja.id} className={styles.caja}>
                <Link to={`/caja/${eventoId}/${caja.id}`}>
                  <div className={styles.contIconCaja}>
                    <Icon icon="ph:cash-register-light" className={styles.icon} />
                  </div>
                  <p>{caja.nombre}</p>
                </Link>
              </li>
            ))}
        </ul>

{newCajas.map((caja, index) => (
  <div key={index} className={styles.newCajaForm}>
    <Icon
      icon="mdi:dots-vertical"
      className={styles.menuIcon}
      onClick={() => toggleMenu(index)}
    />
    <div className={`${styles.menu} ${menuVisible === index ? styles.active : ''}`}>
      <button onClick={(e) => handleCheckboxChange(index, !caja.importar)}>
        {caja.importar ? 'Cancelar importación' : 'Importar de otra caja'}
      </button>
    </div>
    <div className={styles.contIconCaja}>
      <Icon icon="ph:cash-register-light" className={styles.icon} width={45} />
    </div>
    <input
      type="text"
      value={caja.nombre}
      onChange={(e) => handleNombreChange(index, e.target.value)}
      placeholder="Nombre de la nueva caja"
      className={styles.inputCaja}
    />
    {caja.importar && (
      <div className={styles.importarCaja}>
        <p className={styles.importarCajaTexto}>Importar productos de:</p>
        <select
          onChange={(e) => handleSeleccionCaja(index, e.target.value)}
          className={styles.selectorCaja}
          value={caja.cajaSeleccionadaId || ''}
        >
          <option value="">Seleccione una caja</option>
          {cajas.map((existingCaja) => (
            <option key={existingCaja.id} value={existingCaja.id}>
              {existingCaja.nombre}
            </option>
          ))}
        </select>
      </div>
    )}
    <SubmitButton
      isDisabled={caja.isSubmitting}
      isLoading={caja.isSubmitting}
      onClick={() => handleSubmitCaja(index)}
      className={styles.buttonSubmit}
    >
      Guardar caja <Icon width={16} icon="mynaui:save" />
    </SubmitButton>
  </div>
))}



        <div className={styles.addCaja} onClick={handleAddNewCaja}>
          <Icon icon="gravity-ui:plus" className={styles.addIcon} />
        </div>

      </div>

    </div>
  );
};

export default Cajas;