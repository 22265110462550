import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneInputComponent.scss';

const PhoneInputComponent = ({ value, onChange, cambiarBordes }) => {
  
  const handlePhoneChange = (phone) => {
    // Prevenir la eliminación del código de área del país
    if (phone && phone.length > 0) {
      onChange(phone);
    }
  };

  // Determinar la clase CSS basada en la validación
  const getInputClassName = () => {
    if (cambiarBordes) {
      if (!value) return '';
      return isValidPhoneNumber(value) ? 'valid-phone' : 'invalid-phone';
    }else{
      return '';
    }
  };

  return (
    <PhoneInput
      defaultCountry="AR"
      value={value}
      onChange={handlePhoneChange}
      className={`phone-input ${getInputClassName()}`}
      international
      countryCallingCodeEditable={false}
    />
  );
};

export default PhoneInputComponent;
