import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Icon } from "@iconify/react";
import Alert from '../../Components/Alert/Alert';
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import styles from "./CrearMenu.module.scss";
import variables from "../../Context/Variables";

function CrearMenuPage() {
    const [productos, setProductos] = useState([{ id: 1 }]);
    const [loading, setLoading] = useState(false);
    const { eventoId } = useParams(); 
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Crear menú";
        window.scrollTo(0, 0);
      }, []);

      
    const agregarProducto = useCallback(() => {
        if (productos.length >= 25) return;
        setProductos(current => [...current, { id: current.length + 1, titulo: "", precio: null, imagen: null }]);
    }, [productos]);
    
    const handleInputChange = (index, field, event) => {
        console.log(event); // Verificar que el evento está definido
        if (field === 'imagen') {
            const files = event.target.files;
            console.log(files); // Verificar que files está definido
            if (files && files.length > 0) {
                const file = files[0];
                console.log(file); // Verificar que file está definido
                const maxFileSize = 2 * 1024 * 1024; // 2MB
                if (file.size > maxFileSize) {
                    Alert({
                        tipo: 'error',
                        titulo: 'Error',
                        descripcion: 'El archivo supera el tamaño máximo de 2MB.',
                        duracion: 3000
                    });
                    return;
                }
                setProductos(current =>
                    current.map((producto, i) =>
                        i === index ? { ...producto, [field]: file } : producto
                    )
                );
            }
        } else {
            console.log(event.target.value); 
            setProductos(current =>
                current.map((producto, i) =>
                    i === index ? { ...producto, [field]: event.target.value } : producto
                )
            );
        }
    };
    

    const validarFormulario = () => {

        // Ejemplo simple de validación
        for (let producto of productos) {
            if (!producto.titulo || producto.precio <= 0) {
                Alert({
                    tipo: 'error',
                    titulo: 'Error',
                    descripcion: 'Todos los campos deben ser llenados correctamente',
                    duracion: 3000
                });
                return false;
            }
        }

        return true;
    };

    const enviarMenu = (event) => {
        event.preventDefault();
        if (!validarFormulario()) return;
        setLoading(true);
    
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'Debe estar logueado para realizar esta acción.',
                duracion: 3000
            });
            setLoading(false);
            return;
        }
        const details = JSON.parse(userDetails);
        const jwt = details.jwt;
    
        const formData = new FormData();
        formData.append('idEvento', eventoId);
        productos.forEach((producto, index) => {
            formData.append('titulos', producto.titulo);
            formData.append('precios', producto.precio);
            if (producto.imagen && producto.imagen instanceof File) {
                formData.append('imagenes', producto.imagen, producto.imagen.name);
            }
        });
    
        console.log(formData);
        fetch(variables.API_BASE_URL + '/api/menu/crear', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${jwt}` },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if(response.status === 413) {
                    throw new Error('El tamaño del archivo excede el máximo permitido.');
                }
                return response.text().then(text => { throw new Error(text || 'Algo salió mal en el servidor'); });
            }
            return response.json();
        })
        .then(async data => {
            await Alert({
                tipo: 'success',
                titulo: 'Éxito',
                descripcion: 'Menú creado con éxito',
                duracion: 3000
            });
            navigate("/panel-productor");
        })
        .catch(async error => {
            let errorMessage = 'Error desconocido';
            try {
                const errorData = JSON.parse(error.message);
                errorMessage = errorData.message || 'Error desconocido';
            } catch (e) {
                errorMessage = error.message;
            }
            await Alert({
                tipo: 'error',
                titulo: 'Error al crear el menú',
                descripcion: errorMessage,
                duracion: 5000
            });
        })
        .finally(() => {
            setLoading(false);
        });
    };
    
    const eliminarProducto = (index) => {
        setProductos(current => current.filter((_, i) => i !== index));
    };
    
    return (
        <div className={styles.body}>
            
            <div className={styles.crearMenuContainer}>
                <h2>Crea tu menú virtual</h2>
                <div className={styles.fondoDescripcion}>
                    <p>Agrega hasta 25 productos a tu menú. Proporciona detalles como el nombre, precio y una imagen para cada producto.</p>
                </div>

                <form onSubmit={enviarMenu}>
                    <div className={styles.productos}>
                        {productos.map((producto, index) => (
                            <div key={index} className={styles.producto}>
                                <button 
                                    type="button"
                                    className={styles.deleteBtn}
                                    onClick={() => eliminarProducto(index)}
                                >
                                    <Icon icon="solar:trash-bin-trash-bold" width={16} />
                                    Eliminar
                                </button>
                                
                                <div className={styles.productImage}>
                                    <img 
                                        src={producto.imagen ? URL.createObjectURL(producto.imagen) : "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Menus/food.webp"} 
                                        alt="Vista previa"
                                    />
                                    <Icon
                                        icon="uil:edit"
                                        width={35}
                                        className={styles.editIcon}
                                        onClick={() => document.getElementById(`fileInput-${index}`).click()}
                                    />
                                    <input
                                        id={`fileInput-${index}`}
                                        type="file"
                                        accept="image/png, image/jpeg, image/webp"
                                        style={{ display: 'none' }}
                                        onChange={e => handleInputChange(index, 'imagen', e)}
                                    />
                                </div>

                                <label>Título:</label>
                                <input
                                    type="text"
                                    placeholder="Título del Producto"
                                    maxLength="20"
                                    value={producto.titulo || ''}
                                    onChange={e => handleInputChange(index, 'titulo', e)}
                                    className={styles.inputProducto}
                                />

                                <label>Precio:</label>
                                <input
                                    type="number"
                                    placeholder="Precio sin $"
                                    value={producto.precio || ''}
                                    onChange={e => handleInputChange(index, 'precio', e)}
                                    className={styles.inputProducto}
                                />
                            </div>
                        ))}

                        <button 
                            type="button" 
                            onClick={agregarProducto} 
                            disabled={productos.length >= 25} 
                            className={styles.agregarBtn}
                        >
                            <Icon icon="solar:add-circle-broken" width={40} /> Agregar Producto 
                        </button>
                    </div>

                    <SubmitButton
                        isDisabled={loading || !productos.every(p => p.titulo && p.precio > 0)}
                        color="#99419d"
                        isLoading={loading}
                        onClick={(e) => { e.preventDefault(); enviarMenu(e); }}
                        className={styles.submitButton}
                    >
                        Crear Menú Virtual
                    </SubmitButton>
                </form>
            </div>
        </div>
    );
}

export default CrearMenuPage;