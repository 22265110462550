import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Ticket from "../../Components/Ticket/Ticket";
import ButacaTicket from "../../Components/ButacaTicket/ButacaTicket";
import TicketConsumicion from "../../Components/TicketConsumicion/TicketConsumicion";
import styles from "./MisTickets.module.scss";
import variables from "../../Context/Variables";
import TicketSkeleton from "../../Components/Ticket/Skeleton/TicketSkeleton";
import ErrorPage from "../../Components/ErrorPage/ErrorPage";
import { Icon } from "@iconify/react";

const MisTicketsConsumiciones = () => {
  const [tickets, setTickets] = useState([]);
  const [butacas, setButacas] = useState([]);
  const [consumiciones, setConsumiciones] = useState([]);
  const [filtro, setFiltro] = useState("todos"); 
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Mis tickets y consumiciones";
    ensureAuthenticated();
  }, []);

  const ensureAuthenticated = () => {
    const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/login");
    } else {
      buscarDatos();
    }
  };

  const buscarDatos = async () => {
    const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    const details = JSON.parse(userDetails);
    const jwt = details.jwt;

    const endpointTickets = variables.API_BASE_URL + "/api/tickets/comprados";
    const endpointButacas = variables.API_BASE_URL + "/api/butacas/compradas";
    const endpointConsumiciones = variables.API_BASE_URL + "/api/ticketConsumiciones/consumiciones/comprados";

    try {
      const [ticketsResponse, butacasResponse, consumicionesResponse] = await Promise.all([
        axios.get(endpointTickets, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }),
        axios.get(endpointButacas, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }),
        axios.get(endpointConsumiciones, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }),
      ]);

      setTickets(ticketsResponse.data);
      setButacas(butacasResponse.data);
      setConsumiciones(consumicionesResponse.data);
      setCargando(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error al buscar los datos.");
      setCargando(false);
    }
  };

  const elementosFiltrados = () => {
    if (filtro === "tickets") return tickets.map((ticket) => <Ticket key={ticket.id} ticket={ticket} />);
    if (filtro === "butacas") return butacas.map((butaca) => <ButacaTicket key={butaca.id} butaca={butaca} />);
    if (filtro === "consumiciones")
      return consumiciones.map((consumicion) => <TicketConsumicion key={consumicion.id} ticket={consumicion} />);
    return [
      ...tickets.map((ticket) => <Ticket key={ticket.id} ticket={ticket} />),
      ...butacas.map((butaca) => <ButacaTicket key={butaca.id} butaca={butaca} />),
      ...consumiciones.map((consumicion) => <TicketConsumicion key={consumicion.id} ticket={consumicion} />),
    ];
  };

  if (cargando)
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <h2 style={{ color: "#ccc", fontSize: "20px", marginTop: "16px" }}>Cargando tickets...</h2>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          <TicketSkeleton />
          <TicketSkeleton />
          <TicketSkeleton />
        </div>
      </div>
    );

  if (error) return <ErrorPage />;

  return (
    <section className={styles.cuerpoTickets}>
      <div className={styles.infoTickets}>
        <h4 className={styles.tituloEntradas}>
          Mis tickets y consumiciones válidas <Icon icon="solar:check-circle-bold" style={{ color: "#47BD8F" }} />
        </h4>
        <div className={styles.fondoSub}>
          <p>
            Podés <b>descargarlos</b>, <b>hacerle captura</b> o <b>abrir esta misma página.</b>
            <br />
            Recuerda que sea en el formato que sea, deberás <b>presentarlos en el punto de ingreso</b> del evento o en los puntos de venta para ser verificados.
          </p>
        </div>
      </div>

      {/* Botones de filtro */}
      <div className={styles.filtroContainer}>
            <h5>Filtrar por:</h5>
            <div className={styles.botonesFiltro}>
              <button onClick={() => setFiltro("todos")} className={filtro === "todos" ? styles.activo : ""}>
                Todos
              </button>
              <button onClick={() => setFiltro("tickets")} className={filtro === "tickets" ? styles.activo : ""}>
                Entradas <Icon icon="solar:ticket-bold" />
              </button>
              <button onClick={() => setFiltro("consumiciones")} className={filtro === "consumiciones" ? styles.activo : ""}>
                Consumiciones <Icon icon="solar:bottle-bold" />
              </button>
              <button onClick={() => setFiltro("butacas")} className={filtro === "butacas" ? styles.activo : ""}>
                Butacas <Icon icon="solar:chair-bold" />
              </button>
            </div>
          </div>

      <div className={styles.lineaDivisora}></div>

      <div className={styles.ticketsContainer}>
        {elementosFiltrados().length > 0 ? (
          elementosFiltrados()
        ) : (
          <div style={{ textAlign: "center", margin: "40px auto" }}>
            {filtro === "tickets" && (
              <p style={{ marginBottom: "16px" }}>
                Aún no tienes tickets. <br />
                Explora los eventos disponibles y consigue el tuyo.
              </p>
            )}
            {filtro === "butacas" && (
              <p style={{ marginBottom: "16px" }}>
                Aún no tienes butacas reservadas. <br />
                Revisa los eventos con asientos disponibles.
              </p>
            )}
            {filtro === "consumiciones" && (
              <p style={{ marginBottom: "16px" }}>
                Aún no tienes consumiciones. <br />
                Aprovecha nuestras promociones en los eventos.
              </p>
            )}
            {filtro === "todos" && (
              <p style={{ marginBottom: "16px" }}>
                Aún no tienes tickets, butacas ni consumiciones. <br />
                Explora nuestros eventos y adquiere los tuyos.
              </p>
            )}
            <button
              onClick={() => navigate('/')}
              className={styles['boton-ir-a-eventos']}
            >
              Ir a explorar eventos
            </button>


          </div>
        )}
      </div>


    </section>
  );
};

export default MisTicketsConsumiciones;
