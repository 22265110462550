import React, { useState, useEffect } from 'react';
import styles from './AutenticadorUnificado.module.scss';
import { GoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../Context/AuthProvider";
import axios from "axios";
import Alert from "../Alert/Alert";
import SubmitButton from "../Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../Context/Variables";
import PhoneInputComponent from "../PhoneInputComponent/PhoneInputComponent";
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Icon } from "@iconify/react";
import LoaderBolas from "../Buttons-loaders-inputs/LoaderGoogle/LoaderBolas";
import { Link } from 'react-router-dom';
import useDebounce from '../../Hooks/useDebounce';

const AutenticadorUnificado = ({ titulo, rol }) => {
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const debouncedEmail = useDebounce(email, 500);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [userExists, setUserExists] = useState(null);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleAccount, setIsGoogleAccount] = useState(false);

  // Estados para usuario existente (login)
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Estados para nuevo usuario (registro)
  const [formData, setFormData] = useState({
    nombre: "",
    dni: "",
    celular: "",
    contraseña: "",
    contraseñaConfirmada: "",
    terminos: false
  });

  // Agregar nuevo estado para errores de validación
  const [formErrors, setFormErrors] = useState({
    nombre: "",
    dni: "",
    celular: "",
    contraseña: "",
    contraseñaConfirmada: ""
  });

  const validarEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // Verificar email cuando el usuario deja de escribir y el email es válido
  useEffect(() => {
    if (!debouncedEmail) {
      setIsEmailValid(false);
      setUserExists(null);
      setIsCheckingEmail(false);
      return;
    }

    if (!validarEmail(debouncedEmail)) {
      setIsEmailValid(false);
      setUserExists(null);
      setIsCheckingEmail(false);
      return;
    }

    setIsEmailValid(true);
    setIsCheckingEmail(true);

    const controller = new AbortController();
    const signal = controller.signal;

    // Hacer la petición para verificar el email
    setTimeout(() => {
      axios.get(`${variables.API_BASE_URL}/api/usuarios/verificar-email/${debouncedEmail}`, { signal })
        .then(response => {
          setUserExists(response.data.existe);
          setIsGoogleAccount(response.data.esCuentaGoogle);
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log("Petición cancelada");
          } else {
            console.error("Error verificando email:", error);
          }
        })
        .finally(() => {
          setIsCheckingEmail(false);
        });
    }, 1000);

    return () => {
      controller.abort();
    };
  }, [debouncedEmail]);

  const handleEmailChange = (e) => {
    const value = e.target.value.trim();
    setEmail(value);
    setError("");

    // Validar email y aplicar estilos
    const isValid = validarEmail(value);
    const input = e.target;

    if (value && !isValid) {
      input.className = `${styles.emailInput} ${styles.inputError}`;
    } else if (value && isValid) {
      input.className = `${styles.emailInput} ${styles.inputValid}`;
    } else {
      input.className = styles.emailInput;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${variables.API_BASE_URL}/api/usuarios/login`, {
        email,
        password
      });

      login(response.data, false);
      Alert({
        tipo: "success",
        titulo: "Ingreso exitoso",
        descripcion: "Has ingresado exitosamente.",
        duracion: 5000
      });

      const event = new CustomEvent("userAuthenticated", {
        detail: response.data.jwt,
      });
      window.dispatchEvent(event);
    } catch (error) {
      let errorMessage = "Error al iniciar sesión";

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = "No existe un usuario con ese email";
            break;
          case 401:
            errorMessage = "Email o contraseña incorrecta";
            break;
          case 403:
            errorMessage = "Ingrese desde su cuenta de Google";
            break;
          case 500:
            errorMessage = "Error interno del servidor";
            break;
          default:
            errorMessage = error.response.data || errorMessage;
        }
      }

      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const datosParaEnviar = {
        username: formData.nombre,
        password: formData.contraseña,
        email: email,
        dni: formData.dni,
        celular: formData.celular,
      };

      const apiEndpoint = rol === "PRODUCTOR"
        ? "/api/usuarios/registrarProductorYLoguearlo"
        : "/api/usuarios/registrarUsuarioYLoguearlo";

      const response = await axios.post(
        variables.API_BASE_URL + apiEndpoint,
        datosParaEnviar
      );

      login(response.data, false);
      Alert({
        tipo: "success",
        titulo: "Cuenta creada",
        descripcion: "Tu cuenta ha sido creada correctamente.",
        duracion: 3000
      });

      const event = new CustomEvent("userAuthenticated", {
        detail: response.data.jwt,
      });
      window.dispatchEvent(event);
    } catch (error) {
      setError(error.response?.data || "Error al crear la cuenta");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async (googleData) => {
    setGoogleLoading(true);
    setError("");

    try {
      const response = await axios.post(
        `${variables.API_BASE_URL}/api/usuarios/authGoogleWithUser/${rol}`,
        {
          tokenId: googleData.credential
        }
      );

      if (response.data) {
        const { jwt, rol: userRole, username, necesitaCompletarInfo, necesitaAceptarTerminos } = response.data;

        login(response.data, false);

        Alert({
          tipo: "success",
          titulo: "Ingreso exitoso",
          descripcion: "Has ingresado con Google exitosamente.",
          duracion: 5000
        });

        // Disparar evento de autenticación
        const event = new CustomEvent("userAuthenticated", {
          detail: jwt,
        });
        window.dispatchEvent(event);

        // Manejar estados adicionales si es necesario
        if (necesitaCompletarInfo) {
          // Aquí puedes agregar lógica para redirigir a completar información
          console.log("El usuario necesita completar información adicional");
        }

        if (necesitaAceptarTerminos) {
          // Aquí puedes agregar lógica para mostrar términos y condiciones
          console.log("El usuario necesita aceptar los términos y condiciones");
        }
      }
    } catch (error) {
      let errorMessage = "Error al iniciar sesión con Google";

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = "Rol de usuario inválido";
            break;
          case 401:
            errorMessage = "Token de Google inválido o expirado";
            break;
          case 500:
            errorMessage = "Error interno del servidor";
            break;
          default:
            errorMessage = error.response.data || errorMessage;
        }
      }

      setError(errorMessage);

      Alert({
        tipo: "error",
        titulo: "Error de autenticación",
        descripcion: errorMessage,
        duracion: 5000
      });
    } finally {
      setGoogleLoading(false);
    }
  };

  // Agregar funciones de validación
  const validarNombreCompleto = (nombre) => {
    return nombre.trim().length >= 4;
  };

  const validarDNI = (dni) => {
    const regexDNI = /^\d{7,15}$/;
    return regexDNI.test(dni);
  };

  const validarContraseña = (contraseña) => {
    const regexContraseña = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    return regexContraseña.test(contraseña);
  };

  // Modificar el handleChange existente para los campos del formulario
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    let errorMsg = "";
    switch (name) {
      case "nombre":
        if (!validarNombreCompleto(value)) {
          errorMsg = "El nombre debe tener al menos 4 caracteres.";
        }
        break;
      case "dni":
        if (!validarDNI(value)) {
          errorMsg = "El DNI/Pasaporte debe tener entre 7 y 15 dígitos.";
        }
        break;
      case "contraseña":
        if (!validarContraseña(value)) {
          errorMsg = "La contraseña debe tener al menos 6 caracteres, incluir una mayúscula y una minúscula.";
        }
        // Validar también la confirmación si ya existe
        if (formData.contraseñaConfirmada && value !== formData.contraseñaConfirmada) {
          setFormErrors(prev => ({
            ...prev,
            contraseñaConfirmada: "Las contraseñas no coinciden"
          }));
        } else if (formData.contraseñaConfirmada) {
          setFormErrors(prev => ({
            ...prev,
            contraseñaConfirmada: ""
          }));
        }
        break;
      case "contraseñaConfirmada":
        if (value !== formData.contraseña) {
          errorMsg = "Las contraseñas no coinciden";
        }
        break;
      default:
        break;
    }

    setFormErrors(prev => ({ ...prev, [name]: errorMsg }));

    // Aplicar estilos al input
    const input = e.target;
    if (errorMsg) {
      input.style.borderColor = "red";
    } else if (value !== "") {
      input.style.borderColor = "green";
    } else {
      input.style.removeProperty("border-color");
    }
  };

  // Modificar el manejo del teléfono
  const handlePhoneChange = (value) => {
    setFormData(prev => ({ ...prev, celular: value }));

    const errorMsg = !isValidPhoneNumber(value) ? "Debes ingresar un número de teléfono válido." : "";
    setFormErrors(prev => ({ ...prev, celular: errorMsg }));
  };

  return (
    <div className={styles.autenticadorUnificado}>
      <h3>
        {titulo}
      </h3>
      <p>Para realizar una compra ingresa con tu cuenta o crea una desde aquí fácilmente</p>

      {error && <p className={styles.errorGlobal}>{error}</p>}

      <div className={styles.emailSection}>
        <label className={styles.label} htmlFor="email">Email</label>
        <input
          type="email"
          placeholder="ejemplo@gmail.com"
          value={email}
          onChange={handleEmailChange}
          className={`${styles.emailInput} ${email ? (validarEmail(email) ? styles.inputValid : styles.inputError) : ''
            }`}
        />
        {isCheckingEmail && isEmailValid && (
          <div className={styles.loadingLine}></div>
        )}
        {email && !isEmailValid && (
          <p style={{ marginTop: "-10px" }} className={styles.error}>Ingresa un email válido</p>
        )}
      </div>

      {isEmailValid && !isCheckingEmail && (
        <div className={styles.formSection}>
          {userExists ? (
            isGoogleAccount ? (
              <div className={styles.googleAccountMessage}>
                <p>Esta cuenta está registrada con Google. Por favor, utiliza el botón de Google para iniciar sesión.</p>
              </div>
            ) : (
              // Formulario de login
              <form onSubmit={handleLogin}>
                <div className={styles.passwordSection}>
                  <label className={styles.label} htmlFor="password">Contraseña</label>
                  <div className={styles.inputGroup}>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Ingresa tu contraseña"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={styles.passwordInput}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={styles.togglePassword}
                    >
                      <Icon icon={showPassword ? "mdi:eye-off" : "mdi:eye"} />
                    </button>
                  </div>
                </div>
                <SubmitButton
                  isDisabled={!password}
                  isLoading={isLoading}
                  color="hsl(0, 0%, 15%)"
                >
                  Iniciar sesión
                </SubmitButton>

                <div className={styles.links}>
                  <p>¿Olvidaste tu contraseña?</p>
                  <Link target="_blank" rel="noopener noreferrer" to={`/recuperar-contraseña/${encodeURIComponent(email)}`} className={styles.link}>
                    Recupera tu contraseña
                  </Link>
                </div>
              </form>
            )
          ) : (
            // Formulario de registro
            <form onSubmit={handleRegister}>
              <h4 className={styles.registerTitle}>Completá los siguientes datos para crear tu cuenta</h4>
              <div className={styles.registerFields}>
                <div className={styles.inputField}>
                  <label className={styles.label}>Nombre completo</label>
                  <input
                    type="text"
                    name="nombre"
                    placeholder="Nombre completo"
                    value={formData.nombre}
                    onChange={handleFormChange}
                    className={`${styles.textInput} ${formErrors.nombre ? styles.inputError : formData.nombre ? styles.inputValid : ''}`}
                  />
                  {formErrors.nombre && <p className={styles.error}>{formErrors.nombre}</p>}
                </div>

                <div className={styles.inputField}>
                  <label className={styles.label}>DNI/Pasaporte</label>
                  <input
                    type="text"
                    name="dni"
                    placeholder="DNI/Pasaporte sin puntos"
                    value={formData.dni}
                    onChange={handleFormChange}
                    className={`${styles.textInput} ${formErrors.dni ? styles.inputError : formData.dni ? styles.inputValid : ''}`}
                  />
                  {formErrors.dni && <p className={styles.error}>{formErrors.dni}</p>}
                </div>

                <div className={styles.inputField}>
                  <label className={styles.label}>Celular</label>
                  <PhoneInputComponent
                    value={formData.celular}
                    onChange={handlePhoneChange}
                    cambiarBordes={true}
                  />
                  {formErrors.celular && <p className={styles.error}>{formErrors.celular}</p>}
                </div>

                <div className={styles.inputField}>
                  <label className={styles.label}>Contraseña</label>
                  <div className={styles.inputGroup}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="contraseña"
                      placeholder="Contraseña"
                      value={formData.contraseña}
                      onChange={handleFormChange}
                      className={`${styles.passwordInput} ${formData.contraseña
                          ? (formErrors.contraseña ? styles.inputError : styles.inputValid)
                          : ''
                        }`}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={styles.togglePassword}
                    >
                      <Icon width={16} icon={showPassword ? "mdi:eye-off" : "mdi:eye"} />
                    </button>
                  </div>
                  {formErrors.contraseña && (
                    <p className={styles.error}>{formErrors.contraseña}</p>
                  )}
                </div>

                <div className={styles.inputField}>
                  <label className={styles.label}>Confirmar contraseña</label>
                  <div className={styles.inputGroup}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="contraseñaConfirmada"
                      placeholder="Confirmar contraseña"
                      value={formData.contraseñaConfirmada}
                      onChange={handleFormChange}
                      className={`${styles.passwordInput} ${formData.contraseñaConfirmada
                          ? (formErrors.contraseñaConfirmada ? styles.inputError : styles.inputValid)
                          : ''
                        }`}
                      onPaste={(e) => e.preventDefault()}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={styles.togglePassword}
                    >
                      <Icon width={16} icon={showPassword ? "mdi:eye-off" : "mdi:eye"} />
                    </button>
                  </div>
                  {formErrors.contraseñaConfirmada && (
                    <p className={styles.error}>{formErrors.contraseñaConfirmada}</p>
                  )}
                </div>

                <div className={styles.containerCheckbox}>
                  <input
                    type="checkbox"
                    id="terminos"
                    checked={formData.terminos}
                    onChange={(e) => setFormData({ ...formData, terminos: e.target.checked })}
                  />
                  <label htmlFor="terminos" className={styles.checkboxLabel}>
                    <svg viewBox="0 0 64 64" height="1em" width="1em">
                      <path
                        d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                        className={styles.path}
                      ></path>
                    </svg>
                    <p>
                      He leído y acepto los{" "}
                      <Link target="_blank" rel="noopener noreferrer" to={"/terminosYCondiciones"}>
                        términos y condiciones
                      </Link>
                    </p>
                  </label>
                </div>
              </div>

              {error && <p className={styles.error}>{error}</p>}

              <SubmitButton
                isDisabled={!formData.nombre ||
                  !formData.dni ||
                  !formData.celular ||
                  !formData.contraseña ||
                  formData.contraseña !== formData.contraseñaConfirmada ||
                  !formData.terminos}
                isLoading={isLoading}
                color="#99419d"
              >
                Crear cuenta
              </SubmitButton>
            </form>
          )}
        </div>
      )}

      <div className={styles.separator}>o</div>

      <div className={styles.googleContainer}>
        {googleLoading ? (
          <LoaderBolas />
        ) : (
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => {
              setError("Error al conectar con Google");
              Alert({
                tipo: "error",
                titulo: "Error de conexión",
                descripcion: "No se pudo conectar con Google",
                duracion: 5000
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AutenticadorUnificado; 