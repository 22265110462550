import React, { useEffect } from "react";
import styles from "./InfoCrearEvento.module.scss";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import VideoComponent from "../../Components/VideoComponent/VideoComponent";
import LazyImage from "../../Components/LazyImage/LazyImage";
import PasosCrearEvento from "./PasosCrearEvento/PasosCrearEvento";
import Testimonios from "./Testimonios/Testimonios";
import WhatsAppPop from "../../Components/Buttons-loaders-inputs/WhatsAppPop/WhatsAppPop";
import ConfianEnNosotros from "../../Components/ConfianEnNosotros/ConfianEnNosotros";
import ContactSection from "../../Components/ContactSection/ContactSection";

const InfoCrearEvento = () => {

  useEffect(() => {
    document.title = "Crear evento";
  }, []);

  return (
    <div className={styles.infoContainer}>
      <WhatsAppPop />
      <div className={styles.contenedorIntro}>
        <section className={styles.tituloContenedor}>
          <h1>
            Vendé entradas, en un par de clicks.
          </h1>
          <h3>Recibí tus pagos al instante, mejorá tus ventas y reducí problemas con una plataforma más fácil de usar que el promedio.</h3>
          <h4>Creá tu evento ahora <Icon icon="solar:arrow-down-linear" width={20}/> </h4>
        </section>

        <section className={styles.videoContenedor}>
          <VideoComponent />
        </section>

      </div>

      <div className={styles.contenedorCaracteristicas}>

        <div className={styles.caracteristicaItem}>

          <Icon icon="ic:twotone-dashboard-customize" className={styles.icono} />
          <div className={styles.contTexto}>
            <h3>Completa y facil administración</h3>
            <p>Simplificá la venta de entradas con nuestra plataforma intuitiva y fácil de usar. En pocos pasos, podés configurar y gestionar tus eventos.</p>
          </div>
        </div>

        <div className={styles.caracteristicaItem}>

          <Icon icon="ic:twotone-paid" className={styles.icono} />

          <div className={styles.contTexto}>
            <h3>Obtené tu dinero en el momento</h3>
            <p>Con la modalidad directa podrás obtener el dinero de tus entradas al instante, sin esperar que finalice el evento.</p>
          </div>

        </div>

        <div className={styles.caracteristicaItem}>

          <Icon className={styles.icono} icon="ic:twotone-support-agent" />

          <div className={styles.contTexto}>
            <h3>Soporte y capacitación personalizada</h3>
            <p>Acompañamiento en el proceso de gestión del evento y capacitación en el uso para aprovechar al 100% la plataforma.</p>
          </div>
        </div>

      </div>

      <div className={styles.demostracionPanel}>
        <h2 className={styles.tituloPlataforma}>Una plataforma completa y fácil de usar</h2>
        <p>Donde podés gestionar la venta de tus productos en cualquier momento, ver informes en tiempo real, gestionar RRPP y STAFF, ver pagos y clientes en detalle, utilizar el sistema de puntos de venta en efectivo, y editar los datos de tu evento.</p>
        <LazyImage className={styles.imagenConfiguracionEvento} src={"https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/Captura-de-pantalla-2024-09-02-a-las-19.17.31.webp"} alt={"Imagen del panel de configuracion de evento."} />
      </div>

      <Testimonios />

      <PasosCrearEvento />

      <div className={styles.cardContainer}>
        <h3>
          Planes y precios
        </h3>

        <div className={styles.card}>
          <div className={styles.bg}>
            <div className={styles.contH2}>
              <h2>Básico</h2>
            </div>
            <p className={styles.descripcion}>
              Venta de entradas y consumiciones (opcional), cobros del 100% del
              precio a los 3 días de finalizacion del evento.
            </p>
            <h3>Sin cargos</h3>
            <div className={styles.lineaDivisora}></div>
            <div className={styles.contenedorBeneficios}>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  0% de costo.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  1 cuenta de administrador y 3 de staff con scanners de QRs.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Informes en vivo y plataforma de gestión completa.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Soporte y capacitación gratis.
                </p>
              </div>
            </div>

            <Link to="/crear-evento-plan-basico">
              <button className={styles.crearEventoBoton}>
                Crear evento
                <Icon icon="material-symbols:arrow-forward-rounded" className={styles.arrowIcon} />
              </button>
            </Link>

          </div>
          <div className={styles.blob}></div>
        </div>

        <div className={styles.card}>
          <div className={styles.bg}>
            <div className={styles.contH2}>
              <h2>Directo</h2>
            </div>
            <p className={styles.descripcion}>
              Venta de entradas y consumiciones (opcional), todos los cobros efectuados directamente a tu cuenta sin cargo.
            </p>
            <h3>Pagos al instante</h3>
            <div className={styles.lineaDivisora}></div>
            <div className={styles.contenedorBeneficios}>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Dinero en el momento. 4.95% de costo.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  1 cuenta de administrador y 3 de staff con scanners de QRs.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Informes en vivo y plataforma de gestión completa.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Soporte y capacitación gratis.
                </p>
              </div>
            </div>

            <Link to="/crear-evento-plan-directo">
              <button className={styles.crearEventoBoton}>Crear evento</button>
            </Link>
            <Link to="/crear-evento-plan-directo">
              <button className={styles.crearEventoBoton}>
                Crear evento
                <Icon icon="solar:arrow-right-outline" className={styles.arrowIcon} />
              </button>
            </Link>
          </div>
          <div className={styles.blob2}></div>
        </div>

        <div className={styles.card}>
          <div className={styles.bg}>
            <div className={styles.contH2}>
              <h2>Personalizado</h2>
            </div>
            <p className={styles.descripcion}>
              Venta de tickets, consumiciones y ubicaciones (butacas, mesas, etc...). Trabajamos de manera conjunta y personalizada.
            </p>
            <h3>A tu medida</h3>
            <div className={styles.lineaDivisora}></div>
            <div className={styles.contenedorBeneficios}>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Cargo a los usuarios variables.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Venta de ubicaciones.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Informe de ventas y editar evento.
                </p>
              </div>
              <div className={styles.beneficio}>
                <p>
                  <div className={styles.contImg}>
                    <img alt="Ícono" src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/cheque+(3).png"></img>{" "}
                  </div>
                  Soporte preferencial y personalizado.
                </p>
              </div>
            </div>

            <a className={`${styles.crearEventoBoton} ${styles.wsp} ${styles.whatsAppButton}`}
              href={`https://wa.me/+5493436114446?text=${encodeURIComponent("Hola, estoy interesado en crear mi evento. ¿Podrían darme más información?")}`}
              target="_blank"
              rel="noopener noreferrer">
              <b>Hablar con asistente</b>
              <Icon icon="solar:arrow-right-outline" className={styles.arrowIcon} />
            </a>
          </div>
          <div className={styles.blob}></div>
        </div>
      </div>
      
      <ContactSection/>
      <ConfianEnNosotros />
    </div>
  );
};

export default InfoCrearEvento;
