import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './PanelFacturas.module.scss';
import variables from '../../Context/Variables';
import axios from 'axios';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';

const PanelFacturas = () => {
  const [facturas, setFacturas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { estaAutenticado, jwt } = useAuth();
  const [noPermission, setNoPermission] = useState(false);

  useEffect(() => {
    if (!estaAutenticado || !jwt) {
      setNoPermission(true);
      return;
    }

    const fetchFacturas = async (page) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${variables.API_BASE_URL}/api/facturas?page=${page}&size=15`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          }
        );
        setFacturas(response.data.content || []);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        setError("No se pudieron cargar las facturas. Intenta nuevamente más tarde.");
      } finally {
        setLoading(false);
      }
    };

    fetchFacturas(currentPage);
  }, [currentPage, jwt, estaAutenticado]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  if (noPermission) {
    return <NoPermissionPage />;
  }

  return (
    <div className={styles.body}>
      <div className={styles.panelContainer}>
        <div className={styles.headerRow}>
          <h1 className={styles.title}>Listado de Facturas</h1>
        </div>
        {loading && <p>Cargando facturas...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {!loading && !error && (
          <>
            <div className={styles.contenedortabla}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Tipo</th>
                    <th>Pago ID</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Consumidor</th>
                    <th>Collection ID</th>
                    <th>Estado ARCA</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {facturas.map((factura) => (
                    <tr key={factura.id}>
                      <td>{factura.id}</td>
                      <td>{factura.tipo}</td>
                      <td>{factura.pagoId}</td>
                      <td>{factura.fechaEmision  ? new Date(factura.fechaEmision).toLocaleDateString() : 'Fecha inválida'}</td>
                      <td>{factura.monto !== null ? factura.monto.toFixed(2) : '0.00'}</td>
                      <td>{factura.nombreConsumidor}</td>
                      <td>{factura.collectionId}</td>
                      <td>
                        <div className={styles.estadoIndicator}>
                          <span 
                            className={`${styles.circle} ${
                              factura.facturadoEnArca ? styles.success : styles.error
                            }`}
                          />
                        </div>
                      </td>
                      <td>
                        <Link 
                          to={`/factura-detalle/${factura.id}`}
                          className={styles.verDetalleButton}
                        >
                          Ver detalle
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationControls}>
              <button onClick={handlePreviousPage} disabled={currentPage === 0}>
                Anterior
              </button>
              <span>
                Página {currentPage + 1} de {totalPages}
              </span>
              <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                Siguiente
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PanelFacturas;
