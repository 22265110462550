import React, { useState } from 'react';
import styles from './ContactSection.module.scss';
import Alert from "../Alert/Alert";
import variables from "../../Context/Variables";
import SubmitButton from "../Buttons-loaders-inputs/SubmitButton/SubmitButton";
import PhoneInputComponent from "../PhoneInputComponent/PhoneInputComponent";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Icon } from '@iconify/react';

const ContactSection = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [nombreApellido, setNombreApellido] = useState("");
    const [mensaje, setMensaje] = useState("");

    const handlePhoneChange = (phone) => {
        setPhone(phone);
        setIsPhoneValid(isValidPhoneNumber(phone)); 
    };
    
    const handleSendMessage = async (event) => {
        event.preventDefault();
        
        // Validar campos vacíos
        if (!nombreApellido || !phone || !mensaje) {
            await Alert({
                tipo: 'warning',
                titulo: 'Error de validación',
                descripcion: 'Todos los campos son obligatorios. Por favor, complétalos.',
                duracion: 4000,
            });
            return;
        }

        // Validar número de teléfono
        if (!isPhoneValid) {
            await Alert({
                tipo: 'warning',
                titulo: 'Teléfono no válido',
                descripcion: 'Por favor ingresa un número de teléfono válido.',
                duracion: 4000,
            });
            setShowPhoneError(true);
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${variables.API_BASE_URL}/api/contact/enviar-consulta`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nombreApellido,
                    telefono: phone,
                    mensaje,
                }),
            });
            if (response.ok) {
                setIsSubmitted(true);
                // Resetear los estados
                setNombreApellido("");
                setPhone("");
                setMensaje("");
            } else {
                throw new Error('Error al enviar el mensaje.');
            }
        } catch (error) {
            console.error(error);
            await Alert({
                tipo: 'error',
                titulo: 'Error de envío',
                descripcion: 'No se pudo enviar el mensaje. Intenta nuevamente más tarde.',
                duracion: 5000,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {isSubmitted ? (
                    <div className={styles.successContainer}>
                        <Icon icon="ant-design:check-circle-twotone" className={styles.successIcon} />
                        <h2>¡Tu mensaje fue enviado correctamente!</h2>
                        <p className={styles.successMessage}>
                            Gracias por contactarnos, en el día nos comunicaremos con vos.
                        </p>
                    </div>
                ) : (
                    <>
                        <div className={styles.textContainer}>
                            <div className={styles.question}>¿Te gustaría saber más?</div>
                            <div className={styles.subheading}>Escribí acá tu mensaje y nos pondremos en contacto.</div>
                        </div>
                        <form className={styles.formContainer} onSubmit={handleSendMessage}>
                            <input
                                type="text"
                                placeholder="Nombre y Apellido"
                                className={styles.input}
                                required
                                value={nombreApellido}
                                onChange={(e) => setNombreApellido(e.target.value.trim())}
                            />
                            <PhoneInputComponent
                                value={phone}
                                onChange={handlePhoneChange}
                            />
                            {showPhoneError && !isPhoneValid && (
                                <div className={styles.error}>El número de teléfono no es válido.</div>
                            )}
                            <textarea
                                placeholder="Tu mensaje o consulta"
                                className={styles.textarea}
                                required
                                value={mensaje}
                                onChange={(e) => setMensaje(e.target.value.trim())}
                            ></textarea>
                            <SubmitButton
                                isDisabled={isLoading}
                                isLoading={isLoading}
                                className={styles.sendMessageButton}
                            >
                                Enviar Mensaje
                            </SubmitButton>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};
export default ContactSection;