import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import './App.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import ReactGA from 'react-ga';
import HomePage from "./Pages/index/Home";
import LoginPage from "./Pages/loguin/LoginPage";
import Layout from "./Components/Layout/Layout";
import SearchResults from "./Pages/resultados-busqueda/SearchResults";
import Faq from "./Pages/faq/Faq";
import Nosotros from "./Pages/nosotros/Nosotros";
import InfoCrearEvento from "./Pages/crearEvento/InfoCrearEvento";
import EventoPage from "./Pages/evento/EventoPage";
import NotFoundPage from "./Components/NotFoundPage/NotFoundPage";
import Perfil from "./Pages/perfil/Perfil";
import MisTickets from "./Pages/mis-tickets/MisTickets";
import MisEventos from "./Pages/mis-eventos/MisEventos";
import MisDatos from "./Pages/mis-datos/MisDatos";
import TerminosYCondiciones from "./Pages/terminosYCondiciones/TerminosYCondiciones";
import ContectarCuentasMercadoPago from "./Pages/conectar-cuentas-mp/ContectarCuentasMercadoPago";
import ExitoCreacionEvento from "./Pages/exito-vinculacion-mp/ExitoCreacionEvento";
import ResetPasswordForm from "./Pages/recuperar-contraseña/ResetPasswordForm";
import CambiarContraseña from "./Pages/cambiar-contraseña/CambiarContraseña";
import CuerpoTicketsConsumiciones from "./Pages/mis-consumiciones/CuerpoTicketsConsumiociones";
import TicketScanner from "./Pages/scanner-ticket/TicketScanner";
import ConsumicionScanner from "./Pages/scanner-consumiciones/ConsumicionScanner";
import ConfiguracionFinanzas from "./Pages/configuracion-finanzas/ConfiguracionFinanzas";
import PanelProductor from "./Pages/panel-productor/PanelProductor";
import EditarEventoPage from "./Pages/editar-evento/EditarEventoPage";
import InformeVentasPage from "./Pages/informe-ventas/InformeVentasPage";
import MisPagosPage from "./Pages/mis-pagos/MisPagosPage"
import DetallePagoExito from "./Pages/exito/DetallePagoExito";
import CrearMenuPage from "./Pages/crear-menu/CrearMenuPage";
import GestionRrpps from "./Pages/gestion-rrpps/GestionRrpps";
import GestionRrrpEventoPage from "./Pages/gestion-rrpp-evento/GestionRrrpEventoPage";
import DetallePagoFallo from "./Pages/pago-fallido/DetallePagoFallo";
import EnviarQrsPage from "./Pages/generar-y-enviar-qr/EnviarQrsPage";
import EnvioDeQrForm from "./Components/EnvioDeQrForm/EnvioDeQrForm";
import GestionTipoDeTicketsPage from "./Pages/gestion-tickets/GestionTipoDeTicketsPage";
import SignUp from "./Pages/signup/SignUp";
import NuevaContraseñaPage from "./Pages/contraseña-nueva/NuevaContrasenaPage"
import TrabajaConNosotros from "./Pages/trabaja-con-nosotros/TrabajaConNosotros";
import GestionMesas from "./Pages/gestion-mesas/GestionMesas";
import CrearEvento from "./Pages/crear-evento/CrearEvento";
import Hotjar from "./Context/Hotjar";
import SignUpProductor from "./Pages/signup-productor/SignupProductor";
import EventoDetalle from "./Pages/evento-detalle/EventoDetalle";
import ExitoPago from "./Pages/exito-pago/ExitoPago";
import GestionStaff from "./Pages/gestion-staff/GestionStaff";
import GestionStaffEvento from "./Pages/gestion-staff-evento/GestionStaffEvento";
import EventoPagos from "./Pages/evento-pagos/EventoPagos";
import EventoConfiguracion from "./Pages/evento-configuracion/EventoConfiguracion";
import Cajas from "./Pages/cajas/Cajas";
import Caja from "./Pages/caja/Caja";
import ProductosCaja from "./Pages/productos-caja/ProductosCaja";
import ListaImpresoras from "./Pages/impresoras/ListaImpresoras";
import InformeCajaPage from "./Pages/informe-caja/InformeCajaPage";
import FiestaNacionalAvicultura from "./Pages/fiesta-nacional-avicultura/FiestaNacionalAvicultura";
import GeneradorMapaUbicaciones from "./Pages/generador-mapa-ubicaciones/GeneradorMapaUbicaciones";
import GestionButacas from "./Pages/gestion-butacas/GestionButacas";
import FiestaNacionalAcc from "./Pages/fiesta-nacional-asado-con-cuero/FiestaNacionalAcc";
import VerTickets from "./Pages/ver-tickets/VerTickets";
import TerminosYCondicionesProductores from "./Pages/Terminos-Y-Condiciones-Productores/TerminosYCondicionesProductores";
import CarnavalesHasenkamp from "./Pages/carnavales-hasenkamp/CarnavalesHasenkamp";
import PanelRegistrosQr from "./Pages/panel-envios-qr/PanelRegistrosQr";
import VerDetalles from "./Pages/ver-detalles/VerDetalles";
import PanelAdmin from "./Pages/panel-admin/PanelAdmin";
import PanelFacturas from "./Pages/panel-facturas/PanelFacturas";
import FacturaDetalle from "./Pages/factura-detalle/FacturaDetalle";
import PagosLista from "./Pages/pagos-lista/PagosLista";

const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

// Google analitycs 
ReactGA.initialize(googleAnalyticsId);
ReactGA.pageview(window.location.pathname + window.location.search);

function PageTracker() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
}

function App() {

  // Configuracion hotjar
  useEffect(() => {
    Hotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  return (
    <Router>
      <PageTracker />
      <Routes>
        <Route path="signup" element={<SignUp />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="generador-mapa-ubicaciones/:eventoId" element={<GeneradorMapaUbicaciones />} />
        <Route path="caja/:eventoId/:cajaId" element={<Caja />} />

        {/* Rutas con Layout */}
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="index" element={<HomePage />} />
          <Route path="resultados-busqueda" element={<SearchResults />} />
          <Route path="faq" element={<Faq />} />
          <Route path="editar-evento/:eventoId" element={<EditarEventoPage />} />
          <Route path="perfil" element={<Perfil />} />
          <Route path="evento/:id/:rrppId" element={<EventoPage />} />
          <Route path="evento/:id" element={<EventoPage />} />
          <Route path="crearEvento" element={<InfoCrearEvento />} />
          <Route path="conectar-cuentas-mp/:eventoId" element={<ContectarCuentasMercadoPago />} />
          <Route path="exito-vinculacion-mp" element={<ExitoCreacionEvento />} />
          <Route path="crear-evento-plan-basico" element={<CrearEvento tipoPlan="BASICO" />} />
          <Route path="crear-evento-plan-directo" element={<CrearEvento tipoPlan="DIRECTO" />} />
          <Route path="configuracion-finanzas/:id" element={<ConfiguracionFinanzas />} />
          <Route path="nosotros" element={<Nosotros />} />
          <Route path="gestion-rrpps" element={<GestionRrpps />} />
          <Route path="terminosYCondiciones" element={<TerminosYCondiciones />} />
          <Route path="mis-datos" element={<MisDatos />} />
          <Route path="gestion-rrpp-evento/:eventoId/:nombre" element={<GestionRrrpEventoPage />} />
          <Route path="crear-menu/:eventoId" element={<CrearMenuPage />} />
          <Route path="mis-tickets" element={<MisTickets />} />
          <Route path="mis-pagos" element={<MisPagosPage />} />
          <Route path="scanner-ticket/:eventoId" element={<TicketScanner />} />
          <Route path="scanner-consumiciones/:eventoId" element={<ConsumicionScanner />} />
          <Route path="informe-ventas/:id" element={<InformeVentasPage />} />
          <Route path="mis-eventos" element={<MisEventos />} />
          <Route path="exito" element={<DetallePagoExito />} />
          <Route path="pago-fallido" element={<DetallePagoFallo />} />
          <Route path="gestion-tickets/:eventoId/:nombre" element={<GestionTipoDeTicketsPage />} />
          <Route path="mis-consumiciones" element={<CuerpoTicketsConsumiciones />} />
          <Route path="cambiar-contraseña" element={<CambiarContraseña />} />
          <Route path="recuperar-contraseña" element={<ResetPasswordForm />} />
          <Route path="recuperar-contraseña/:emailUrl" element={<ResetPasswordForm />} />
          <Route path="contrasena-nueva/:token" element={<NuevaContraseñaPage />} />
          <Route path="panel-productor" element={<PanelProductor />} />
          <Route path="enviar-qrs/:eventoId" element={<EnviarQrsPage />} />
          <Route path="generar-y-enviar-qr/:eventoId/:tipoDeTicketId/:nombreEvento/:nombreTicket" element={<EnvioDeQrForm />} />
          <Route path="trabaja-con-nosotros" element={<TrabajaConNosotros />} />
          <Route path="gestion-mesas/:eventoId" element={<GestionMesas />} />
          <Route path="signup-productor" element={<SignUpProductor />} />
          <Route path="evento-detalle/:id" element={<EventoDetalle />} />
          <Route path="evento-detalle/:id/:rrppId" element={<EventoDetalle />} />
          <Route path="pago-exitoso" element={<ExitoPago />} />
          <Route path="gestion-staff" element={<GestionStaff />} />
          <Route path="gestion-staff-evento/:eventoId/:nombre" element={<GestionStaffEvento />} />
          <Route path="evento-pagos/:eventoId/:nombre" element={<EventoPagos />} />
          <Route path="evento-configuracion/:eventoId/:nombre/:imgUrl" element={<EventoConfiguracion />} />
          <Route path="evento-configuracion/:eventoId/:nombre" element={<EventoConfiguracion />} />
          <Route path="cajas/:eventoId/:nombre" element={<Cajas />} />
          <Route path="productos-caja/:eventoId/:cajaId" element={<ProductosCaja />} />
          <Route path="impresoras" element={<ListaImpresoras />} />
          <Route path="informe-caja/:eventoId/:cajaId" element={<InformeCajaPage />} />
          <Route path="gestion-butacas/:eventoId/:nombre" element={<GestionButacas />} />
          <Route path="TerminosYCondicionesProductores" element={<TerminosYCondicionesProductores />} />
          <Route path="ver-tickets/:token" element={<VerTickets />} />
          <Route path="ver-tickets/:token/:nombreUsuario/:dni" element={<VerTickets />} />
          <Route path="panel-envios-qr/:eventoId/:nombreEvento" element={<PanelRegistrosQr />} />
          <Route path="/detalles-envio/:idRegistro/:eventoId" element={<VerDetalles />}/>
          <Route path="/panel-admin" element={<PanelAdmin/>}/>
          <Route path="/panel-facturas" element={<PanelFacturas/>}/>
          <Route path="/factura-detalle/:id" element={<FacturaDetalle/>}/>
          <Route path="/panel-admin/pagos" element={<PagosLista/>}/>

          {/* Fiestas Nacionales */}
          <Route path="fiesta-nacional-avicultura" element={<FiestaNacionalAvicultura />} />
          <Route path="fiesta-nacional-asado-con-cuero" element={<FiestaNacionalAcc />} />
          <Route path="carnavales-hasenkamp-2025" element={<CarnavalesHasenkamp />} />

          {/* Ruta no encontrada */}
          <Route path="*" element={<NotFoundPage />} />

        </Route>

      </Routes>
    </Router>
  );
}

export default App;