import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import styles from './ButacaTicket.module.scss';
import { Icon } from "@iconify/react";

const ButacaTicket = ({ butaca }) => {
    const ticketRef = useRef(null);
    const downloadButtonRef = useRef(null);

    const descargarEntrada = () => {
        if (ticketRef.current) {
            if (downloadButtonRef.current) {
                downloadButtonRef.current.style.display = "none";
            }

            html2canvas(ticketRef.current, { allowTaint: true, useCORS: true })
                .then((canvas) => {
                    const link = document.createElement('a');
                    link.href = canvas.toDataURL('image/png');
                    link.download = `BUTACA-ID:${butaca.id}.png`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.error('Algo salió mal!', error);
                })
                .finally(() => {
                    if (downloadButtonRef.current) {
                        downloadButtonRef.current.style.display = "block";
                    }
                });
        }
    };

    // Genera query string para evitar caché en imágenes
    const randomQueryStringVerificado = "?nocache=" + new Date().getTime();
    const srcVerificado = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Tickets/Verified.svg.png" + randomQueryStringVerificado;

    return (
        <div ref={ticketRef} className={styles.ticketCont}>
            <div className={styles.logoTicket}>
                <div className={styles.detallesTicket}>
                    <p className={styles.textoVerificado}>
                        TICKET DE BUTACA VERIFICADO <img src={srcVerificado} alt="Verificado" crossOrigin="anonymous" />
                    </p>
                    <p className={styles.id}>ID: {butaca.id.slice(0,7)+"..."}</p>
                </div>
                <div className={styles.contTitulo}>
                    <h1>simple<span>pass</span></h1>
                </div>
            </div>

            <div className={styles.datosTicket}>
                <h2>{butaca.nombreEvento}</h2>
                <span>Sección: {butaca.seccion}</span>
                <span>Fila: {butaca.fila}</span>
                <span>Número de Butaca: {butaca.numeroButaca}</span>
                <span>Fecha del Evento: {butaca.fechaEvento}</span>
            </div>

            <div className={styles.imagenQR}>
                <img src={butaca.urlImgQr + "?nocache=" + new Date().getTime()} alt="Código QR" crossOrigin="anonymous" />
            </div>

            <button 
                ref={downloadButtonRef}
                className={styles.btnDescargar} 
                onClick={descargarEntrada}
            >
               Descargar <Icon width={20} icon="solar:download-minimalistic-bold" />
            </button>
        </div>
    );
};

export default ButacaTicket;